import React from 'react';
import {connect} from "react-redux";

import {IndividualResource,ContentBlock,PreviousAndNextModule,Loading,Error} from "./Embeds/Common.js"
import {fetchModule,fetchAdjacentModules} from "../actions/modulesActions"
import {fetchUserIndividualCourseProgress,setProgress} from "../actions/coursesActions"
import {switchURLS,setActiveMenu} from "../actions/commonActions"
import {fetchResources} from "../actions/resourcesActions"

import {CommonTitle,getLanguage,SetHeaderColorCode,Analytics,LogAnalyticsEvent} from "../API"
import {CoursesLabel,AnErrorOccuredLabel,PageDoesNotExistError} from "../WebCopy.js";

const mapStateToProps = (state) => {
  return {
  	loading: state.modulesReducer.loading,
  	error: state.modulesReducer.error,
  	success: state.modulesReducer.success,
    selectedModule: state.modulesReducer.selectedModule, 
    adjacentModules: state.modulesReducer.adjacentModules,

    loggedIn: state.accountsReducer.loggedIn,

    resources: state.resourcesReducer.resources,
    individualCompletedModules: state.coursesReducer.individualCompletedModules,
    markingDone: state.coursesReducer.markingDone
  };
}

const mapDispatchToProps = {
  setProgress,
  fetchModule,
  switchURLS,
  fetchUserIndividualCourseProgress,
  setActiveMenu,
  fetchResources,
  fetchAdjacentModules
}


class ModulePage extends React.Component{
  constructor(props){
    super(props)

    this.moduleInfo = <Loading />
    this.colorCode = "#F2F2F3";

    this.title = <span className="fa fa-spin fa-spinner"/>
    this.breadCrumbs = [];

    this.processedModule = false
    this.lessonCompleted = false

    this.resources = null;

    this.timeOutTimer = null
  }

  computeSlug = () =>{
      this.slug = window.location.pathname.split("/")
      this.slug = this.slug[this.slug.length-1]
  }

  //this timer is used to determine if the user has stayed on the page for 4 minutes then mark them as they have already 
  //read the module
  setMarkedTimer = () =>{
    
      if(this.timeOutTimer!==null){
        clearTimeout(this.timeOutTimer)
      }

      if(this.props.loggedIn && !this.lessonCompleted){
        let self = this
        this.timeOutTimer = window.setTimeout(function(){
            self.markDone()
        },240000)      
      }

  }

  componentDidMount(){
      window.scrollTo(0,0)
      this.computeSlug()
      this.props.setActiveMenu("courses")
      document.title = "... | "+CommonTitle 
      this.props.fetchModule(this.slug)
  }

  componentWillUnmount(){
    if(this.timeOutTimer!==null){
      clearTimeout(this.timeOutTimer)
    }
  }

  componentDidUpdate(props){
    if(this.props.location.key !== props.location.key || this.props.loggedIn !== props.loggedIn){
          window.scrollTo(0,0)

          this.moduleInfo = <Loading />
          this.title = <span className="fa fa-spin fa-spinner"/>
          this.breadCrumbs = [];

          this.processedModule = false


          this.computeSlug()
          this.props.fetchModule(this.slug)
    }
  }

  calculateBreadCrumbs = () =>{
        this.breadCrumbs = [];
        let link = {}

        link["name"] = CoursesLabel()
        if(getLanguage()==="en"){
          link["path"] = "/courses"
        }else{
          link["path"] = "/kozi"
        }
        this.breadCrumbs.push(link);

        link = {}
        if(getLanguage()==="en"){
          link["name"] = this.props.selectedModule.course_name
          link["path"] = "/courses/"+this.props.selectedModule.english_course_slug
        }else{
          link["name"] = this.props.selectedModule.swahili_course_name
          link["path"] = "/kozi/"+this.props.selectedModule.swahili_course_slug
        }
        this.breadCrumbs.push(link);

        link = {}
        if(getLanguage()==="en"){
          link["name"] = this.props.selectedModule.english_name
          link["path"] = "/modules/"+this.props.selectedModule.english_slug
        }else{
          link["name"] = this.props.selectedModule.swahili_name
          link["path"] = "/matoleo/"+this.props.selectedModule.swahili_slug
        }
        this.breadCrumbs.push(link);

  }

  markDone = () =>{
    if(this.props.markingDone===true){
      return 
    }

    LogAnalyticsEvent("User Interaction","Mark lesson as done","",true)
    this.props.setProgress(this.props.loggedIn,this.props.selectedModule.course_id,this.props.selectedModule.ID)
  }

  processModule = () =>{
    if(this.props.loading===true && this.props.selectedModule===null){
        return
    }


    if(this.props.error!==null && this.props.loading===false){
      this.title = AnErrorOccuredLabel()
      this.moduleInfo = <Error Message={PageDoesNotExistError()} />
      return
    }


    if(this.props.selectedModule===null){
      return
    }

    SetHeaderColorCode(this.props.selectedModule.color_code)
    this.colorCode = this.props.selectedModule.color_code

    /*
    this.lessonCompleted = false
    for(let x = 0 ; x < this.props.individualCompletedModules.length ; x++){
      if(this.props.individualCompletedModules[x] !== null && this.props.individualCompletedModules[x].module===this.props.selectedModule.ID){
        this.lessonCompleted = true
        break
      }
    }
    */

    document.title = ( getLanguage() === "en" ? this.moduleInfo.english_name : this.moduleInfo.swahili_name ) +" | "+CommonTitle
    if(this.processedModule===false){
        Analytics()
        this.calculateBreadCrumbs()
        this.moduleInfo = this.props.selectedModule
        this.title = getLanguage()==="en" ? this.props.selectedModule.english_name : this.props.selectedModule.swahili_name
        this.props.switchURLS({"en":"/module/"+this.props.selectedModule.english_slug,"sw":"/toleo/"+this.props.selectedModule.swahili_slug})
    
        this.processedModule = true

        if(this.props.loggedIn!==null ){
          // this.props.fetchUserIndividualCourseProgress(this.props.loggedIn,this.props.selectedModule.course_id,0,10000)
          this.progressFetched=true
        }

        this.props.fetchResources(0,1000,this.props.selectedModule.ID)

        this.props.fetchAdjacentModules(this.props.selectedModule.course_id,this.props.selectedModule.placement_order)

        // this.setMarkedTimer()
    }

  }


	render(){

    this.processModule()

		return (
      <>
        {
          this.props.selectedModule!==null ? 
          <div className="row noMargin moduleTitleSpace" style={{backgroundColor:this.colorCode}}>
            <p></p>
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-10 m-auto">
                  <h2 className="pageSubtitle">{getLanguage()==="en" ? this.props.selectedModule.course_name : this.props.selectedModule.swahili_course_name}</h2>
                  <h1 className="modulePageTitle">{getLanguage()==="en" ? this.props.selectedModule.english_name : this.props.selectedModule.swahili_name}</h1>
                </div>
              </div>
            </div>
          </div>

          : null
        }

        <div className="row noMargin">
          <div className="container">

            <ContentBlock 
              info={this.moduleInfo} 
              showBack={true}
             />

              <div className="row">
                <div className="col-sm-12 col-md-10 m-auto">
                  <div className="row">
                        { 
                          this.props.resources.length > 0 ? 
                                <>
                                {
                                  this.props.resources.map((row,index)=>{
                                      return <IndividualResource resource={row} key={index} />                            
                                  })
                                }
                                </>
                          : null
                        }
                  </div>
                </div>

                  {
                    this.props.loggedIn!==null ?
                      null
                      /*

                      <div className="col-12 text-center">
                        <hr/>
                        <p>
                          <strong className="capitalize">{LessonCompleteLabel()}</strong>
                        </p>
                        {
                          this.lessonCompleted === false ? 
                            <span  className="btn greyButton" onClick={this.markDone}>
                              {
                                this.props.markingDone===true ? 
                                <span className="fa fa-spin fa-spinner"></span> :
                                <strong>{MarkCompleteLabel()}</strong>
                              }
                            </span>
                          : 
                          <p>
                              <span className="fa fa-check-circle redText fa-2x" title={CompletedValueLabel()}></span>
                          </p>
                        }
                        <hr/>
                      </div>
                      */

                    :null
                  }
              </div>

            {
              this.props.adjacentModules.length > 0 && this.props.selectedModule !== null ? 
              <PreviousAndNextModule 
                currentModule={this.props.selectedModule} 
                modules={this.props.adjacentModules}
              />
              :
              null
            }


          </div>
        </div>
      </>
		)		
	}

}

const comp = connect(mapStateToProps,mapDispatchToProps)(ModulePage);
export default comp;
