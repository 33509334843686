// import {APICall} from "../API.js"

export const FETCH_PROGRESS_BEGIN   = 'FETCH_PROGRESS_BEGIN';
export const FETCH_PROGRESS_SUCCESS = 'FETCH_PROGRESS_SUCCESS';
export const FETCH_PROGRESS_FAILURE = 'FETCH_PROGRESS_FAILURE';

export const FETCH_MORE_PROGRESS_BEGIN   = 'FETCH_MORE_PROGRESS_BEGIN';
export const FETCH_MORE_PROGRESS_SUCCESS = 'FETCH_MORE_PROGRESS_SUCCESS';
export const FETCH_MORE_PROGRESS_FAILURE = 'FETCH_MORE_PROGRESS_FAILURE';

export const fetchProgressBegin = () => ({
  type: FETCH_PROGRESS_BEGIN
});

export const fetchProgressSuccess = progress => ({
  type: FETCH_PROGRESS_SUCCESS,
  payload: { progress } 
});

export const fetchProgressFailure = error => ({
  type: FETCH_PROGRESS_FAILURE,
  payload: { error }
});

export const fetchMoreProgressBegin = () => ({
  type: FETCH_MORE_PROGRESS_BEGIN
});

export const fetchMoreProgressSuccess = progress => ({
  type: FETCH_MORE_PROGRESS_SUCCESS,
  payload: { progress } 
});

export const fetchMoreProgressFailure = error => ({
  type: FETCH_MORE_PROGRESS_FAILURE,
  payload: { error }
});

// const port = "" 

export function fetchProgress(start,count,user_id=null,appending=false) {
  return async dispatch => {

      return
      /*
      let url = port+"/get-progress?start="+start+"&count="+count+"&admin=true"

      if(user_id!==null){
        url += "&user="+user_id
      }

      switch(appending){
        case true:{
          dispatch(fetchMoreProgressBegin());
          let response = await APICall(url,null,"GET")
          if(response.body!=null){
            switch(response.body.code){
              case 200:{
                dispatch(fetchMoreProgressSuccess(response.body));
                break;
              }
              default:{
                dispatch(fetchMoreProgressFailure(response.body.message))
                break
              }
            }
          }else{
            dispatch(fetchMoreProgressFailure(response.error))
          }

          break
        }
        case false:{
          dispatch(fetchProgressBegin());
          let response = await APICall(url,null,"GET")
          if(response.body!=null){
            switch(response.body.code){
              case 200:{
                dispatch(fetchProgressSuccess(response.body));
                break;
              }
              default:{
                dispatch(fetchProgressFailure(response.body.message))
                break
              }
            }
          }else{
            dispatch(fetchProgressFailure(response.error))
          }
          break
        }
        default:
          break
      }
      */

  };
}
