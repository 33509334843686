import React from 'react';
import {Link} from 'react-router-dom';
import YouTube from 'react-youtube';

import {ResourcesBaseURL,getLanguage,SetHeaderColorCode,LogAnalyticsEvent} from "../../API.js";

import {AboutUsLabel,CoursesLabel,ResourcesLabel,
        EnglishLabel,
        SwahiliLabel,FooterNotesLabel,
        AboutNoaUbongoLabel,WatchHereLabel,
        HomeLabel,CompletedLabel,PullMoreLabel,
        AdjacentLesson,CompletedValueLabel,LoginSignUp,
        LoginSignUpTitle,CourseLabel,ModuleLabel,
        Name,DownloadLabel,ContactUs,CourseTitle} from "../../WebCopy.js";

export function Loading(){
  return(

      <div className="row noMargin">
          <div className="col-12 text-center">
              <img src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/bars.gif" className="img-fluid" alt="Loading..." />
          </div>
      </div>

  )

}

export function Error(props){
    return(
      <div className="row noMargin">
          <div className="col-12 text-center text-danger">
            <p></p>
            <span className="fa fa-exclamation-triangle fa-3x"></span>
            <h4>{props.Message}</h4>
            <p></p>
          </div>
      </div>  
    )
}

export function Menu(props){
  const loginContent = <LoginRegister 
                            logout={props.logout}
                            loggedInName={props.loggedInName}
                            loggedIn={props.loggedIn}
                            currentLang={props.language} 
                            changeLanguage={props.changeLanguage} 
                            switchUrls={props.switchUrls}
                          />

  return(
          <>
          <div className="row noMargin fixed-top_" id="menuPanel">
            <div className="col-12">
              <div className="container">
                <div className="row">

                  <div className="col-sm-12 col-md-10 m-auto noPadding">

                      <nav className="navbar navbar-expand-lg navbar-light">
                        <Link className="navbar-brand m-auto" to={getLanguage()==="en" ? "/en" :"/"} title={HomeLabel()}>
                            <img src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/latest-logo.png" className="img-fluid" alt="Noa Ubongo" id="topLogo" />
                        </Link>

                        <div className="mr-auto d-md-flex d-lg-none loginRegister showOptionsOnMobile">
                          
                          {loginContent}                          

                        </div>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                          <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                          <ul className="navbar-nav ml-auto" id="mainMenu">
                            <li className="nav-item ">
                              {
                                window.location.pathname==="/" || window.location.pathname==="/en" ? 
                                  <a href="#about" className="nav-link">
                                      {AboutUsLabel()}
                                  </a> : 
                                <Link to={getLanguage() === "en" ? "/en#about" : "/#about"} className="nav-link">
                                    {AboutUsLabel()}
                                </Link>
                              }
                            </li>
                            <li className="nav-item">
                              <Link to={getLanguage() === "en" ? "/courses" : "/kozi" } 
                                    title={CoursesLabel()} 
                                    className={"nav-link " + (props.activeMenu ==="courses"  || props.activeMenu==="kozi" ? "active" : "") }>
                                  {CoursesLabel()}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={getLanguage() === "en" ? "/resources" : "/rasilimali"} 
                                className={"nav-link " + (props.activeMenu ==="resources"  || props.activeMenu==="rasilimali" ? "active" : "") }>
                                  {ResourcesLabel()}
                              </Link>
                            </li>
                          </ul>

                          <div className="hideMobile loginRegister">
                            <span>&nbsp;&nbsp;</span>
                            {loginContent}                          
                          </div>


                        </div>
                      </nav>


                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="buffer"></div>
          <LoginModal login={props.login} fbLogin={props.fbLogin} googleLogin={props.googleLogin}/>
          </>


    )
}

//
function LoginRegister(props){

  return(
      <div className="ml-auto languageSwitchers">
          {
            /*
            props.loggedIn !== null ? 
              <span>
                <i>
                  <span className="fa fa-user"></span> {props.loggedInName}
                </i>
                <span>&nbsp;&nbsp;</span>
                <i 
                    className="fa fa-sign-out-alt cursor redText" 
                    title={signOut()} 
                    onClick={props.logout}>
                </i>  
              </span>
              :
              <span>
                  <a href="login#" className="signUpSignIn" data-toggle="modal" data-target="#myModal">
                    {SignUpLabel()}
                  </a>
                  <a href="signup#" className="signUpSignIn signInButton redButton" data-toggle="modal" data-target="#myModal">
                    {SignInLabel()}
                  </a>
              </span>
              */
          }

                <span className="hideMobile">
                &nbsp;&nbsp;&nbsp;
                </span>

                      <p className="clearfix vissibleOnMobile"/>
                      <Link to={props.switchUrls.en} className={"inheritText blackText " + (props.currentLang==="en"? "active" : "")} onClick={()=>props.changeLanguage("en")}>
                        <span>
                        {
                        	/*
                          <img src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/en.gif" className="img-fluid" alt={EnglishLabel()} />
                          */
                        }
                        </span>
                        {
                            EnglishLabel() 

                        }
                      </Link>
                      <span> | </span>
                      <Link to={props.switchUrls.sw}  className={"inheritText blackText "+(props.currentLang==="sw"? "active" : "")}  onClick={()=>props.changeLanguage("sw")}>
                        <span>
                        {
                        	/*
                          <img src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/sw.gif" className="img-fluid" alt={SwahiliLabel()} />
                          */
                        }
                        </span>
                        {
                               SwahiliLabel()
                           }
                      </Link>


      </div>
  )
}

//
function LoginModal(props){
  return(

      <div id="myModal" className="modal fade" role="dialog">
        <div className="modal-dialog">

          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title redText">{LoginSignUpTitle()}</h4>
              <button type="button" className="close" data-dismiss="modal" id="loginModalClose">&times;</button>
            </div>
            <div className="modal-body text-center">
              <img src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/logo.png" id="loginLogo" className="img-fluid" alt="logo"/>
              <p>{LoginSignUp()}</p>
              <div className="text-center loginOptionsHolder">
                    <span className="cursor loginOption" onClick={()=>props.fbLogin()} title="Facebook">
                        <img className="blackIcon img-fluid" alt="Facebook Black" src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/fb-black.png" />
                        <img className="redIcon img-fluid" alt="Facebook Red" src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/fb-red.png" />
                    </span>
                    <span>&nbsp;&nbsp;</span>
                    <span className="cursor loginOption" onClick={()=>props.googleLogin()} title="Google">
                        <img className="blackIcon img-fluid" alt="Google Black" src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/google-black.png" />
                        <img className="redIcon img-fluid" alt="Google Red" src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/google-red.png" />
                    </span>

              </div>
              <p></p>
            </div>
          </div>

        </div>
      </div>

    )
}

//
export function Masthead(props){
  return(
      <div className="row text-center">
          <div className="col-12">
            <a href="#about">
              <img src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/resources/new-noa-ubongo-masthead.png" className="w-100" alt="masthead" />
            </a>
          </div>
      </div>
    )
}

//
export function Footer(props){
  return(

      <footer className="row">
        <div className="container">

          <div className="row pageSegment">
            <div className="col-sm-12 col-md-10 m-auto">
              <div className="row noMargin">
                <div className="col-sm-12 col-md-7">
                  <p id="footerLinks">
                    <a href="https://www.facebook.com/noaubongo" target="_blank" rel="noreferrer nofollow noopener" className="blackText">
                      <i className="fab fa-facebook-f fa-2x"></i>
                    </a>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <a href="https://www.instagram.com/noaubongo/" target="_blank" rel="noreferrer nofollow noopener" className="blackText">
                      <i className="fab fa-instagram fa-2x"></i>
                    </a>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <a href="https://www.youtube.com/channel/UCegOb7gc46wYne1Vz9MhA6w" target="_blank" rel="noreferrer nofollow noopener" className="blackText">
                      <i className="fab fa-youtube fa-2x"></i>
                    </a>
                  </p>
                  <h3 className="footerText">{FooterNotesLabel()}</h3>
                  <h3 className="footerText">{ContactUs()}</h3>
                </div>
                <div className="col text-center">
                  <img id="khangaruelogo" src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/latest-krm-logo.png" className="img-fluid" alt="Khangarue" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </footer>
    )
}

//
export function ProgressBar(props){
  return(
        <div className="progressBar">
          <div 
              className="progressIndicator" 
              style={{width: props.value.toFixed(0)+"%"}} 
              title={CompletedValueLabel()+" "+props.value.toFixed(0)+"%" }/>
        </div>

    )
}

//
export function Courses(props){
      return (
          <div className="row noMargin">
            <div className="container">

              <div className="col-sm-12 col-md-10 m-auto ">
                  <div className="row">
                    {
                      props.home === true ?
                      <ListCourses 
                        Courses={props.Courses} 
                        progress={props.CoursesProgress}
                      />
                      :
                      <ListCoursesForCoursesPage 
                        Courses={props.Courses} 
                        progress={props.CoursesProgress} 
                      />
                    }
                  </div>
              </div>
            </div>
          </div>
        )      

}

//
function ListCourses(props){

   let courses = null
   if(props.Courses===undefined){
      return null
   }

   courses = props.Courses.map((row,index)=>{
      let progressVal = 0
      for(let x = 0 ; x < props.progress.length ; x++){
          if(props.progress[x] !== null && props.progress[x].course===row.ID){
              progressVal = ( props.progress[x].modules_covered / row.modules_count ) * 100
              break
          }
      }

      const name = getLanguage() === "en" ? row.english_name : row.swahili_name
      let link =  "/courses/"+row.english_slug
      if(getLanguage()==="sw"){
        link = "/kozi/"+row.swahili_slug
      }

      let description = row.english_description
      if(  getLanguage() !== "en"  ){
        description = row.swahili_description
      }

      const maxLength = 250

      return(

          <div className="col-sm-12 col-md-3 homeCourseParent" key={index}>
            <Link to={link} className="inheritLink h-100">
                <div className="homeCourseEncloser text-center h-100" style={{"background":row.color_code}}>
                  <div className="homeImageProgresssBarHolder">
                    <img src={ResourcesBaseURL+row.poster} 
                        className="w-100" 
                      alt={name}/>
                    <ProgressBar value={progressVal}/>
                  </div>
                  <img src={ResourcesBaseURL+row.icon} className="img-fluid courseIcon" alt={name} />
                  <div className="col-12 descriptionEncloser">
                    <h2 className="courseModuleTitle">
                      {name}
                    </h2>
                    <p className="courseDescription">
                      {
                        description.substring(0,maxLength)
                      }
                      {
                        description.length>maxLength?"...":null

                      }
                    </p>
                    {
                      getLanguage() === "en" ?
                      <span to={"/courses/"+row.english_slug} className="watchHere" title={name}>{WatchHereLabel()}</span> :
                      <span to={"/kozi/"+row.swahili_slug} className="watchHere" title={name}>{WatchHereLabel()}</span>
                    }
                  </div>
                </div>
            </Link>
          </div>

      )
   })
   return courses
}

//
function ListCoursesForCoursesPage(props){

   let courses = null
   if(props.Courses===undefined){
      return null
   }

   courses = props.Courses.map((row,index)=>{
      let progressVal = 0
      for(let x = 0 ; x < props.progress.length ; x++){
          if(props.progress[x] !== null && props.progress[x].course===row.ID){
              progressVal = ( props.progress[x].modules_covered / row.modules_count ) * 100
              break
          }
      }

      const name = getLanguage() === "en" ? row.english_name : row.swahili_name
      const img = ResourcesBaseURL +  row.internal_poster
      const segment = <div className="CourseEncloser text-center w-100" style={{"backgroundImage":"url("+img+")"}}>
                        <ProgressBar value={progressVal}/>
                        {
                          /*
                        <h2>
                          {name}
                        </h2>
                          */
                        }
                      </div>

      return(

          <div className="col-sm-12 col-md-6 CourseParent" key={index}>
            {
              getLanguage() === "en" ?
              <Link to={"/courses/"+row.english_slug} className="blackText" title={name}>
                {segment}
                <h2 className="courseModuleTitle text-left">
                  {name}
                </h2>
              </Link> :
              <Link to={"/kozi/"+row.swahili_slug} className="blackText" title={name}>
                {segment}
                <h2 className="courseModuleTitle text-left">
                  {name}
                </h2>
              </Link>
            }
          </div>

      )
   })
   return courses
}

//
export function IndividualResource(props){
  let path = getLanguage() === "en" ? props.resource.path_en : props.resource.path_sw
  return (
      <div className="col-sm-6 col-md-4 courseAndModuleResourceEncloser">
        <div className="courseAndModuleResourceInnerEncloser h-100" style={{borderColor:props.resource.course_color}}>
          <label className="individualResourceTitle">{getLanguage()==="en" ? props.resource.english_name : props.resource.swahili_name}</label>
          <div className="resourceCourseAndInfoEncloser">
            <span className="greyText courseTitleAndModuleInfo">{CourseTitle()} : <span className="courseAndModuleInfoDetails">{getLanguage()==="en" ? props.resource.course_name_english : props.resource.course_name_swahili}</span> </span><br/>
            <span className="greyText courseTitleAndModuleInfo"><span className="courseAndModuleInfoDetails">{getLanguage()==="en" ? props.resource.module_name_english : props.resource.module_name_swahili}</span> </span><br/>
          </div>


          <a className="courseResourceDownloadButton" href={ResourcesBaseURL+path} target="_blank" rel="nofollow noreferrer noopener">
            Download
            <span className="fa fa-play-circle"></span>
          </a>
        </div>
      </div>
    )
}

//
function VideoAction(action){
  LogAnalyticsEvent("User Interaction","User Interacted with the embeded played",action,true)    
}

//
function YoutubeVideo(props){

  let url = props.url.split("v=")
  url = url[1]
  url = url.split("&")
  url = url[0]
//  url = "https://www.youtube.com/embed/"+url
  let height = "300"
  if( props.height !== undefined && props.height !== null )
    height = props.height 

  const opts = {
      height: height,
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        modestbranding:1,
      },
    };

  return (
    <YouTube
      opts={opts}
      videoId={url}                  // defaults -> null
      onPlay={()=>VideoAction("Play")}  
      onPause={()=>VideoAction("Pause")}
      onEnd={()=>VideoAction("Pause")}
      onPlaybackRateChange={()=>VideoAction("Playback Rate Changed")} 
      onPlaybackQualityChange={()=>VideoAction("Quality Changed")}
    />

  )

}

//
export function AboutSection(props){

  return(

    <div className="row noMargin">
      <div className="container">
        <div className="row pageSegment">
          <div className="col-sm-12 col-md-10 m-auto" id="aboutZone">
            <div className="row">
              <a href="#about" name="about">&nbsp;</a>
              <div className="col-sm-12 col-md-6">
                <div className="col-sm-12 col-md-10">
                  <h1 className="homeAboutTitle" dangerouslySetInnerHTML={{__html: AboutNoaUbongoLabel()}} >
                  </h1>
                  {
                    props.aboutCopy===null || props.aboutCopy.ID!==1?
                    <Loading /> :              
                    <p className="midSizedText">
                      { getLanguage()==="en" ? props.aboutCopy.english_description : props.aboutCopy.swahili_description }
                    </p>
                  }
                </div>
              </div>
              <div className="col text-center">
                  <YoutubeVideo url="https://www.youtube.com/watch?v=UhLeDsJPrKY&list=PLgn3eESEPSTSl0h8tAO1KHMI8DVeLFoJk&index=2" />
              </div>

            </div>
            </div>
          </div>
      </div>
    </div>
    )
}

//
export function TitleStrip(props){
  /*
  breadcrumbs are not used anymore
  let links = []
  let thisLink = {}
  thisLink["name"] = HomeLabel()
  thisLink["path"] = getLanguage()==="en" ? "/en" : "/"
  links.push(thisLink)

  if(props.breadCrumbs!==undefined){
    for(let x = 0 ; x < props.breadCrumbs.length ; x++){
      links.push(props.breadCrumbs[x])
    }
  }

  let lastIndex = links.length - 1
  let slash = ""
  links = links.map((row,index)=>{
    if(index>0){
      slash = " / "
    }
    if(index!==lastIndex){
        return <strong key={index}>
          <Link to={row.path} className="inheritLink">{slash}{row.name}</Link>
        </strong>
    }
    return <span key={index} className="inheritLink">{slash}{row.name}</span>
  })
  */


  return(
          <div className="row noMargin pageTitleSegment">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-10 m-auto text-center">
                      <h1 className="pageTitle capitalize">{props.title}</h1>
                </div>
              </div>
            </div>
          </div>
  )
}

//
export function PullMore(props){
  return(

    <div className="row">
      <div className="col-12 text-center">
        <span onClick={props.action} className="btn redButton pullMore">
          {
              props.loading===true ? <span className="fa fa-spin fa-spinner"></span> :
              PullMoreLabel()
          }
        </span>
        <p></p>
      </div>    
    </div>

    )
}

//
export function Modules(props){

    return (
        <div className="row">
            <div className="col-sm-12 col-md-10 m-auto">
                <div className="row">
                    <ListModules Modules={props.Modules} 
                                 modulesCount={props.modulesCount} 
                                 coveredModules={props.coveredModules}
                    />
                </div>
            </div>
        </div>
      )
}

//
function ListModules(props){
   let modules = null
   if(props.Modules===undefined){
      return null
   }

   modules = props.Modules.map((row,index)=>{
      let completed = null

      for(let x = 0 ; x < props.coveredModules.length ; x++){
          if(props.coveredModules[x]!==null && props.coveredModules[x].module===row.ID){
            completed = <ModuleCompleted />
            break
          }
      }

      let name = getLanguage() === "en" ? row.english_name : row.swahili_name
      let link = null

      if(getLanguage()==="en"){
          link = "/module/"+row.english_slug
      }else{
          link = "/toleo/"+row.swahili_slug
      }

      return(

            <div className="col-sm-12 col-md-4 homeCourseParent" key={index}>
              <div className="moduleEncloser text-center ">
                {completed}
                <Link to={link}>
                  <img src={ResourcesBaseURL+(getLanguage()==="en" ? row.english_poster : row.swahili_poster)} 
                    className="w-100 " alt={name} />
                </Link>
                <div className="col-12 text-left noPadding">
                  <h2 className="courseModuleTitle">
                    <Link className="inheritLink" to={link}>
                      <span className="episodeLabel"> {name}</span>
                    </Link>
                  </h2>
                </div>
              </div>
            </div>

      )
   })
   return modules
}

export function ContentBlock(props){
  if( props.info.ID===undefined){
    return props.info 
  }

  let name = getLanguage() === "en" ? props.info.english_name : props.info.swahili_name
  return(
            <div className="row">
              <div className="col-sm-12 col-md-10 m-auto coursePageDescription">
                {
                  props.ShowBack===true ?
                  <p>
                    <strong><span className="fa fa-chevron-circle-left"></span> Back to <u className="redText">course</u></strong>
                  </p> :
                  null
                }
                {
                  props.info.video !== null && props.info.video !== " " && props.info.video !== undefined ?
                    <YoutubeVideo url={props.info.video} height="550" /> :
                    <img 
                      src={ResourcesBaseURL+(getLanguage() === "en" ? props.info.english_poster : props.info.swahili_poster)} 
                      className="img-fluid"
                      alt={name}
                     />
                }
                <br/>
                <p>
                  {

                    getLanguage() === "en" ? 
                      props.info.english_description
                      :
                      props.info.swahili_description
                  }
                </p>
              </div>
            </div>

    )
}

//
export function ModuleCompleted(props){
  return(
      <strong className="moduleCompleted" title={CompletedLabel()}>
        <span className="fa fa-check-circle completedCheck fa-2x"></span>
      </strong>
  )
}

function trackDownload(){
    LogAnalyticsEvent("User Interaction","User Downloads Resource","",true)    
}

//
export function DownloadMaterials(props){
  const links = props.Links.map((row,index)=>{
    let name = getLanguage() === "en" ? row.english_name : row.swahili_name
    let path = getLanguage() === "en" ? row.path_en : row.path_sw
    let content = <span>- {name} &nbsp;&nbsp;<span className="fa fa-file-download greyText"></span></span>
    return(
            <li key={index}>
                        { 
                            isNaN(parseInt(props.loggedIn))===false ? 
                              <a 
                                onClick={()=>trackDownload()}
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                                className="inheritLink"
                                download
                                href={ResourcesBaseURL+path}> 
                                  {content}
                              </a>

                            :
                            <span data-toggle="modal" data-target="#myModal">
                              {content}
                            </span>
                        }
            </li>
      )
  })
  return(
        <ul className="supportingContents">
          {links}
        </ul>

    )
}

//
export function PreviousAndNextModule(props){
	console.log(props)
  const mods = props.modules.map((row,index)=>{
    
    let name = getLanguage() === "en" ? row.english_name : row.swahili_name
    let link = getLanguage() === "en" ? "/module/"+row.english_slug : "/toleo/"+row.swahili_slug
   	console.log(link)

    if(row.placement_order < props.currentModule.placement_order){
      return (

            <div className="col-sm-12 col-md-6 nextPrevContainer mr-auto" key={index}>
              <div className="prevNextModule h-100 ml-auto">
                {
                  /*
                    <strong>{AdjacentLesson("previous")}</strong>
                  */
                }
                <div className="nextPrevContentHolder">
                    <Link className="inheritLink text-center" to={link}>
                        <h2 className="prevNextTitle"><span className="fa fa-angle-double-left"></span>&nbsp;{AdjacentLesson("previous")}</h2>
                        <h2 className="nextPreviousTitle whiteText">
                          {name}
                        </h2>
                    </Link>
                    {
                      /*
                        <p className="nextPreviousDescription text-center">
                          {description}
                        </p>

                      */
                    }
                </div>
              </div>
            </div>


        )
    }else{

        return(

            <div className="col-sm-12 col-md-6 nextPrevContainer ml-auto" key={index}>
              <div className="prevNextModule h-100 mr-auto">
                {
                  /*
                <strong>{AdjacentLesson("next")}</strong><br/>

                  */
                }
                <div class="nextPrevContentHolder">
                  <Link className="inheritLink text-center" to={link}>
                        <h2 className="prevNextTitle">{AdjacentLesson("next")}&nbsp;<span className="fa fa-angle-double-right"></span></h2>
                        <h2 className="nextPreviousTitle whiteText">
                          {name}
                        </h2>
                  </Link>
                  {
                    /*
                      <p className="nextPreviousDescription text-center">
                        {description}
                      </p>
                    */
                  }
                </div>
              </div>
            </div>



          )

    }
  })

  return(

    <div className="row">
      <div className="col-sm-12 col-md-10 m-auto">
        <div className="row">


          {mods}


        </div>
      </div>
    </div>

    )
}

//
export function ResourcesTable(props){
  const content = <strong> {DownloadLabel()} <span className="fa fa-file-download greyText"></span></strong>
  const resources = props.resources.map((row,index)=>{
      let path = getLanguage() === "en" ? row.path_en : row.path_sw
      let name = getLanguage() === "en" ? row.english_name : row.swahili_name
      let link =  isNaN(parseInt(props.loggedIn))===false ? 
          <a 
            onClick={()=>trackDownload()}
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="inheritLink"
            download
            href={ResourcesBaseURL+path}> 
            {content}
          </a>

        :
        <span data-toggle="modal" data-target="#myModal">
            {content}
        </span>
     

      return(
                <tr key={index}>
                  <td>{index+1}</td>
                  <td>
                        <img src={ResourcesBaseURL+row.course_icon} className="img-fluid iconAtResources" alt={getLanguage()==="en" ? row.course_name_english : row.course_name_swahili} />
                        &nbsp;
                        {getLanguage()==="en" ? row.course_name_english : row.course_name_swahili}
                  </td>
                  <td>{getLanguage()==="en" ? row.module_name_english : row.module_name_swahili}</td>
                  <td>{name}<br/>{link}</td>
                </tr>

      )
  })

  return(
          <table className="table">
            <thead>
              <tr>
                  <th>#</th>
                  <th>{CourseLabel()}</th>
                  <th>{ModuleLabel()}</th>
                  <th>{Name()}</th>
              </tr>
            </thead>
            <tbody>
              {resources}
            </tbody>

          </table>

  )
}

export function CourseHeader(props){
  if(props.courseDetails===null){
    return <Loading />
  }

  SetHeaderColorCode(props.courseDetails.color_code)
  let title = props.courseDetails.english_name
  let description = props.courseDetails.english_description
  if(getLanguage()==="sw"){
    title = props.courseDetails.swahili_name
    description = props.courseDetails.swahili_description
  }

  return <div className="row courseTitleSegment noMargin" style={{"backgroundColor":props.courseDetails.color_code}}>
      <div className="container">
          <div className="row">
              <div className="col-sm-12 col-md-10 m-auto">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                      <h1 className="coursePagrTitle">
                          {title}
                      </h1>
                      <div className="pageUnderline"></div>
                      <p>
                        {description}
                      </p>
                  </div>
                  <div className="col-sm-12 col-md-6">
                      <img src={ResourcesBaseURL+props.courseDetails.masthead} className="courseImage" alt={title} />
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
}