import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom"

import {Masthead,Courses,AboutSection,Loading,Error} from "./Embeds/Common.js"

import {getLanguage,SetHeaderColorCode,CommonTitle,Analytics} from "../API"
import {CoursesLabel,ViewAllCoursesLabel,HomeLabel} from "../WebCopy.js";

import {fetchCourses,fetchUserCourseProgress} from "../actions/coursesActions"
import {fetchPage} from "../actions/pagesActions"
import {setActiveMenu,switchURLS} from "../actions/commonActions"

const mapStateToProps = (state) => {
  return {
  	loading: state.coursesReducer.loading,
  	error: state.coursesReducer.error,
  	success: state.coursesReducer.success,
    courses: state.coursesReducer.courses, 
    courseProgress: state.coursesReducer.courseProgress,
    lastPullCount: state.coursesReducer.lastPullCount,

    selectedPage: state.pagesReducer.selectedPage,

    loggedIn: state.accountsReducer.loggedIn
  };
}

const mapDispatchToProps = {
  fetchCourses,
  fetchPage,
  fetchUserCourseProgress,
  setActiveMenu,
  switchURLS
}


class Home extends React.Component{
  constructor(props){
    super(props)
    //this will have a list of courses we need to find progress for for a given user
    this.gatherProgressFor = []
    this.courses = null
    this.homePageCoursesLimit = 4
    this.aboutUs = null
    document.title = HomeLabel() + " | " + CommonTitle
    Analytics()
  }

  componentDidMount(){
    window.scrollTo(0,0)
    SetHeaderColorCode()
    this.props.setActiveMenu(null)
    this.props.switchURLS({"en":"/en","sw":"/"})
    if(this.props.courses.length===0){
      this.props.fetchCourses(0,this.homePageCoursesLimit,null,false)
    }

  
    if(this.props.selectedPage===null || this.props.selectedPage.ID!==1){
      this.props.fetchPage(null,1)
    }
  }

  processCourses = () =>{
    this.courses = <Loading />
    if(this.props.loading===true){
      return
    }

    if(this.props.error!==null && this.props.courses.length===0){
        this.courses = <Error Message={this.props.error} />
        return
    }

    const tempCourses = this.props.courses.slice(0,this.homePageCoursesLimit)
    //we are only interested with the first few courses for the home page

    let found = false
    let newIds = ""
    for(let x = 0 ; x < tempCourses.length ; x++){

        found = false
        for(let y = 0 ; y < this.props.courseProgress.length ; y++){

            if(this.props.courseProgress[y] !== null && this.props.courseProgress[y].ID === tempCourses[x].ID){
                found = true
                break
            }

        }

        if(found===false){

            if( this.gatherProgressFor.includes(tempCourses[x].ID)===false ){
                this.gatherProgressFor.push( tempCourses[x].ID )


                if(newIds===""){
                  newIds = tempCourses[x].ID
                }else{
                  newIds += ","+tempCourses[x].ID
                }

            }  

        }

    }


    if(this.props.loggedIn!==null && newIds!==""){
      this.props.fetchUserCourseProgress(this.props.loggedIn,newIds)
    }

    this.courses =  <Courses 
            Courses={tempCourses} 
            CoursesProgress={this.props.courseProgress}
            home={true}
        />

  }

  processAboutPage = () =>{
    if(this.props.selectedPage===null){
      return
    }

    this.aboutUs = this.props.selectedPage
    this.latestCourse = <AboutSection 
                            aboutCopy = {this.aboutUs}
                            />

  }

	render(){

    this.processAboutPage()
    this.processCourses()

		return (
      <>
  		<Masthead />
        <div className="row">
	        <div className="container">
	          <div className="row pageSegment">
	            <div className="col-12">
	              <h1 className="text-center capitalize" id="homeCoursesTitle">{CoursesLabel()}</h1>
	              {this.courses}
	              
	                <div className="col-12 text-center" id="homeMoreCoursesButtonHolder">
	                  <Link to={getLanguage()==="en"?"/courses":"/kozi"} id="homeMoreCoursesButton" className="btn redButton m-auto">{ViewAllCoursesLabel()}</Link>
	                </div>


	              {this.latestCourse}
	            </div>
	          </div>
	        </div>
      </div>
      </>
		)		
	}

}

const comp = connect(mapStateToProps,mapDispatchToProps)(Home);
export default comp;
