import {APICall} from "../API.js"

export const FETCH_RESOURCE_BEGIN   = 'FETCH_RESOURCE_BEGIN';
export const FETCH_RESOURCE_SUCCESS = 'FETCH_RESOURCE_BEGIN';
export const FETCH_RESOURCE_FAILURE = 'FETCH_RESOURCE_BEGIN';

export const FETCH_MORE_RESOURCES_BEGIN   = 'FETCH_MORE_RESOURCES_BEGIN';
export const FETCH_MORE_RESOURCES_SUCCESS = 'FETCH_MORE_RESOURCES_SUCCESS';
export const FETCH_MORE_RESOURCES_FAILURE = 'FETCH_MORE_RESOURCES_FAILURE';

export const FETCH_RESOURCES_BEGIN   = 'FETCH_RESOURCES_BEGIN';
export const FETCH_RESOURCES_SUCCESS = 'FETCH_RESOURCES_SUCCESS';
export const FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE';

export const fetchResourcesBegin = () => ({
  type: FETCH_RESOURCES_BEGIN
});

export const fetchResourcesSuccess = resources => ({
  type: FETCH_RESOURCES_SUCCESS,
  payload: { resources }
});

export const fetchResourcesFailure = error => ({
  type: FETCH_RESOURCES_FAILURE,
  payload: { error }
});

export const fetchMoreResourcesBegin = () => ({
  type: FETCH_MORE_RESOURCES_BEGIN
});

export const fetchMoreResourcesSuccess = resources => ({
  type: FETCH_MORE_RESOURCES_SUCCESS,
  payload: { resources }
});

export const fetchMoreResourcesFailure = error => ({
  type: FETCH_MORE_RESOURCES_FAILURE,
  payload: { error }
});


export const fetchResourceBegin = () => ({
  type: FETCH_RESOURCE_BEGIN
});

export const fetchResourceSuccess = resource => ({
  type: FETCH_RESOURCE_SUCCESS,
  payload: { resource } 
});

export const fetchResourceFailure = error => ({
  type: FETCH_RESOURCE_FAILURE,
  payload: { error }
});

const port = ""

export function fetchResource(id) {
  return async dispatch => {

      let url = port+"/get-resource?id="+id
      dispatch(fetchResourceBegin());
      let response = await APICall(url,null,"GET")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(fetchResourceSuccess(response.body.payload));
              break
            }
            default:{
              dispatch(fetchResourceFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(fetchResourceFailure(response.error))
      }

  };
}


export function fetchResources(start=0,count=100000,module=null) {
  return async dispatch => {

      let url = port+"/get-resources?start="+start+"&count="+count+"&admin=true&module="+module

      dispatch(fetchResourcesBegin());
      let response = await APICall(url,null,"GET")
      if(response.body!=null){
        switch(response.body.code){
          case 200:{
            dispatch(fetchResourcesSuccess(response.body.payload));
            break;
          }
          default:{
            dispatch(fetchResourcesFailure(response.body.message))
            break
          }
        }
      }else{
        dispatch(fetchResourcesFailure(response.error))
      }

  };
}
