export const SWITCH_LANGUAGE   = 'SWITCH_LANGUAGE';
export const SWITCH_URLS   = 'SWITCH_URLS';
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'

export const switchLanguage = (targetLanguage) => ({
  type: SWITCH_LANGUAGE,
  payload: {targetLanguage}
});


//this function receives the 2 alrernative pages incase on language switch, i.e english and swahili page respectively
export const switchURLS = (urlsObject) =>({
  type: SWITCH_URLS,
  payload: {urlsObject}
})

export const setActiveMenu = (activeMenu) =>({
	type:SET_ACTIVE_MENU,
	payload:{activeMenu}
})