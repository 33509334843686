import React from 'react';
import {accountLoginSuccess,accountLogin,addAccount} from "./actions/accountsActions.js";
import {switchLanguage} from "./actions/commonActions.js";

import {connect} from "react-redux";
import { BrowserRouter as Router,Switch, Route } from "react-router-dom";
import {getCookie,getLanguage,setLanguage,LogAnalyticsEvent} from "./API.js";
import {Menu,Footer} from "./components/Embeds/Common.js"

import Home from "./components/Home.js"
import CoursesPage from "./components/Courses.js"
import CoursePage from "./components/Course.js"
import ModulePage from "./components/Module.js"
import ResourcesPage from "./components/Resources.js"

import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';

const mapStateToProps = (state) => {
  return {
    loggedIn: state.accountsReducer.loggedIn, 
    loggedInName: state.accountsReducer.loggedInName,

    language: state.commonReducer.language,
    switchUrls: state.commonReducer.switchUrls,
    activeMenu: state.commonReducer.activeMenu
  };
}

const mapDispatchToProps = {
  accountLoginSuccess,
  accountLogin,
  addAccount,
  switchLanguage
}

class App extends React.Component{

  constructor(props){
    super(props)
    this.account = null
  }

  changeLanguage = (lan) =>{
      if(this.props.language===lan){
        return
      }
      setLanguage(lan)
      this.props.switchLanguage(lan)
  }

  componentDidUpdate(props){
    if(this.props.language!==getLanguage()){
        this.changeLanguage(getLanguage())
    }
    if(this.props.loggedIn!==props.loggedIn){
      document.getElementById("loginModalClose").click()
    }
  }

  componentDidMount(){

      this.props.switchLanguage(getLanguage())

      const accessToken = getCookie("access-token")
      if( 
         accessToken !== null && accessToken !== undefined && accessToken !== ""
      ){

        if( isNaN(parseInt(getCookie("loggedIn"))) || getCookie("loggedIn") === "" || getCookie("loggedIn") === null ){
          return
        }

        const tmp = {"ID":parseInt(getCookie("loggedIn")),"name":getCookie("loggedInName")}
        this.props.accountLoginSuccess(tmp)
      }
  

  }

  logout = () =>{
    LogAnalyticsEvent("User Interaction","User Logout","",true)
    this.props.accountLogin(null,null,"logout")
  }

  facebookLogin = () =>{
      document.getElementsByClassName("kep-login-facebook")[0].click()
  }

  googleLogin = () =>{
    document.getElementsByClassName("googleButton")[0].click()
  }

  googleLoginResponse = (response) =>{
    this.account = {"name":response.profileObj.name,"login_key":response.profileObj.googleId,"password":response.profileObj.googleId+"-google","channel":"google"}
    this.props.accountLogin(this.account.login_key,this.account.password,"login",this.loginCallBack)
  }

  facebookLoginResponse = (response) =>{
    this.account = {"name":response.name,"login_key":response.id,"password":response.id+"-facebook","channel":"facebook"}
    this.props.accountLogin(this.account.login_key,this.account.password,"login",this.loginCallBack)
  }

  loginCallBack = (response) =>{
    if(response.code!==200){
      LogAnalyticsEvent("User Inteaction","User Registration","",true)
      this.props.addAccount(this.account,this.accountAddedSuccesfully)
    }
    LogAnalyticsEvent("User Inteaction","User Login","",true)
  }


 accountAddedSuccesfully = (response) =>{
    this.props.accountLogin(response.login_key,response.password,"login")
    console.log(response)
 }

  login = (username="adminUser",password="projectPassword") =>{ 
    LogAnalyticsEvent("User Inteaction","User Login","",true)
    this.props.accountLogin(username,password,"login")
  }

  render(){
    return(

        <Router>

            <div className="hidden">

                  <GoogleLogin
                      clientId="247545635642-tsp1nj4li24un1fhea57tfehrnqb82p8.apps.googleusercontent.com"
                      buttonText="Login"
                      onSuccess={this.googleLoginResponse}
                      cookiePolicy={'single_host_origin'}
                      className="googleButton"
                    />


                  <FacebookLogin
                      appId="1088597931155576"
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={this.facebookLoginResponse} />
            </div>


          <Menu 
              loggedIn={this.props.loggedIn}
              loggedInName={this.props.loggedInName}
              logout={this.logout}
              login={this.login}
              fbLogin={this.facebookLogin}
              googleLogin={this.googleLogin}
              language={this.props.language} 
              changeLanguage={this.changeLanguage}
              switchUrls={this.props.switchUrls}
              activeMenu={this.props.activeMenu}
          />
           {
            /*
              HOME PAGE
            */
           }
           <Switch>
             <Route path="/" exact render={(props)=><Home {...props}  />}  />
             <Route path="/en" exact render={(props)=><Home {...props}  />}  />
             
             <Route path="/courses" exact render={(props)=><CoursesPage {...props}  />}  />
             <Route path="/kozi" exact render={(props)=><CoursesPage {...props}  />}  />

             <Route path="/courses/:slug" exact component={CoursePage}  />
             <Route path="/kozi/:slug" exact component={CoursePage}  />

             <Route path="/module/:slug" exact component={ModulePage}  />
             <Route path="/toleo/:slug" exact component={ModulePage}  />

             <Route path="/resources" exact component={ResourcesPage}  />
             <Route path="/rasilimali" exact component={ResourcesPage}  />

             <Route exact render={(props)=><Home {...props}  />}  />
           </Switch>

          <Footer />
        </Router>

    )
  }

}

const comp = connect(mapStateToProps,mapDispatchToProps)(App);
export default comp;
