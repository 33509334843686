import React from 'react';
import {connect} from "react-redux";

import {IndividualResource,TitleStrip,Error,Loading} from "./Embeds/Common.js"
import {fetchResources} from "../actions/resourcesActions.js"
import {fetchPage} from "../actions/pagesActions"
import {getLanguage,SetHeaderColorCode,Analytics,CommonTitle} from "../API"
import {AnErrorOccuredLabel,ResourcesLabel} from "../WebCopy"

import {setActiveMenu,switchURLS} from "../actions/commonActions"

const mapStateToProps = (state) => {
  return {
  	loading: state.resourcesReducer.loading,
  	error: state.resourcesReducer.error,
  	success: state.resourcesReducer.success,
    resources: state.resourcesReducer.resources, 
    lastPullCount: state.resourcesReducer.lastPullCount,

    selectedPage: state.pagesReducer.selectedPage,

    loggedIn : state.accountsReducer.loggedIn
  };
}

const mapDispatchToProps = {
  fetchPage,
  fetchResources,
  setActiveMenu,
  switchURLS
}


class ResourcesPage extends React.Component{
  constructor(props){
    super(props)
    this.resources = <Loading />
    this.breadCrumbs = [];

    let link = {}
    link["name"] = ResourcesLabel()
    if(getLanguage()==="en"){
      link["path"] = "/resources"
    }else{
      link["path"] = "/rasilimali"
    }

    this.breadCrumbs.push(link);      


    this.start = 0
    this.append = false
    document.title = ResourcesLabel() + " | " + CommonTitle

    Analytics()
  }

  pullMore = () =>{
    this.start = this.props.resources.length;
    this.append = true
    this.fetch()
  }

  fetch = () =>{
    this.props.fetchResources(this.start,100000,null)

  }

  componentDidMount(){
    window.scrollTo(0,0)
    SetHeaderColorCode()
    this.props.setActiveMenu("resources")
    this.props.switchURLS({"en":"/resources","sw":"/rasilimali"})
    this.fetch()

    if(this.props.selectedPage===null || this.props.selectedPage.ID!==2){
      this.props.fetchPage(null,2)
    }

  }


  processResources = () =>{
    if(this.props.loading===true && this.props.resources.length===0){
      return
    }

    if(this.props.loading===false && this.props.error){
      this.resources = <Error message={AnErrorOccuredLabel} />
      return
    }

    this.resources = this.props.resources.map((row,index)=>{
      return <IndividualResource resource={row} key={index} />
    })
  }

	render(){
    this.processResources()

    console.log(this.props.selectedPage)

		return (
      <>
        <div className="row noMargin text-center">
          <div className="col-12 noPadding">
            <img src="https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/resources-masthead.png" className="img-fluid" alt="Noa Ubongo Resources" />
          </div>
        </div>

        <TitleStrip title={ResourcesLabel()} 
                breadCrumbs={this.breadCrumbs}
        />
        <div className="row noMargin">
          <div className="container">

            <div className="row">
              <div className="col-sm-12 col-md-10 m-auto text-center">
                
                <p></p>
                  {
                    this.props.selectedPage !== null && this.props.selectedPage.ID===2 ? 
                      <p className="text-left">
                        {getLanguage() === "en" ? this.props.selectedPage.english_description : this.props.selectedPage.swahili_description }
                      </p>

                    : null
                  }
                <div className="row text-left">
                  {this.resources}
                </div>

              </div>
            </div>

          </div>
        </div>
      </>
		)		
	}

}

const comp = connect(mapStateToProps,mapDispatchToProps)(ResourcesPage);
export default comp;
