import {APICall} from "../API.js"

export const FETCH_COURSES_BEGIN   = 'FETCH_COURSES_BEGIN';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE';

export const FETCH_MORE_COURSES_BEGIN   = 'FETCH_MORE_COURSES_BEGIN';
export const FETCH_MORE_COURSES_SUCCESS = 'FETCH_MORE_COURSES_SUCCESS';
export const FETCH_MORE_COURSES_FAILURE = 'FETCH_MORE_COURSES_FAILURE';

export const FETCH_COURSE_BEGIN   = 'FETCH_COURSE_BEGIN';
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export const FETCH_COURSE_FAILURE = 'FETCH_COURSE_FAILURE';

export const FETCH_LATEST_COURSE_BEGIN   = 'FETCH_LATEST_COURSE_BEGIN';
export const FETCH_LATEST_COURSE_SUCCESS = 'FETCH_LATEST_COURSE_SUCCESS';
export const FETCH_LATEST_COURSE_FAILURE = 'FETCH_LATEST_COURSE_FAILURE';

export const FETCH_PREV_NEXT_COURSES_BEGIN   = 'FETCH_PREV_NEXT_COURSES_BEGIN';
export const FETCH_PREV_NEXT_COURSES_SUCCESS = 'FETCH_PREV_NEXT_COURSES_SUCCESS';
export const FETCH_PREV_NEXT_COURSES_FAILURE = 'FETCH_PREV_NEXT_COURSES_FAILURE';

export const FETCH_USER_COURSES_PROGRESS_BEGIN = 'FETCH_USER_COURSES_PROGRESS_BEGIN';
export const FETCH_USER_COURSES_PROGRESS_SUCCESS = 'FETCH_USER_COURSES_PROGRESS_SUCCESS';
export const FETCH_USER_COURSES_PROGRESS_FAILURE = 'FETCH_USER_COURSES_PROGRESS_FAILURE';

export const FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_BEGIN = 'FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_BEGIN';
export const FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_SUCCESS = 'FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_SUCCESS';
export const FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_FAILURE = 'FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_FAILURE';

export const SET_PROGRESS_DONE_BEGIN = 'SET_PROGRESS_DONE_BEGIN'; 
export const SET_PROGRESS_DONE_SUCCESS = 'SET_PROGRESS_DONE_SUCCESS'; 
export const SET_PROGRESS_DONE_FAILURE = 'SET_PROGRESS_DONE_FAILURE' 

export const fetchCoursesBegin = () => ({
  type: FETCH_COURSES_BEGIN
});

export const fetchCoursesSuccess = courses => ({
  type: FETCH_COURSES_SUCCESS,
  payload: { courses }
});

export const fetchCoursesFailure = error => ({
  type: FETCH_COURSES_FAILURE,
  payload: { error }
});

export const fetchMoreCoursesBegin = () => ({
  type: FETCH_MORE_COURSES_BEGIN
});

export const fetchMoreCoursesSuccess = courses => ({
  type: FETCH_MORE_COURSES_SUCCESS,
  payload: { courses }
});

export const fetchMoreCoursesFailure = error => ({
  type: FETCH_MORE_COURSES_FAILURE,
  payload: { error }
});


export const fetchCourseBegin = () => ({
  type: FETCH_COURSE_BEGIN
});

export const fetchCourseSuccess = course => ({
  type: FETCH_COURSE_SUCCESS,
  payload: { course } 
});

export const fetchCourseFailure = error => ({
  type: FETCH_COURSE_FAILURE,
  payload: { error }
});


export const fetchLatestCourseBegin = () => ({
  type: FETCH_LATEST_COURSE_BEGIN
});

export const fetchLatestCourseSuccess = course => ({
  type: FETCH_LATEST_COURSE_SUCCESS,
  payload: { course } 
});

export const fetchLatestCourseFailure = error => ({
  type: FETCH_LATEST_COURSE_FAILURE,
  payload: { error }
});

export const fetchPrevNextCourseBegin = () => ({
  type: FETCH_PREV_NEXT_COURSES_BEGIN
});

export const fetchPrevNextCourseSuccess = courses => ({
  type: FETCH_PREV_NEXT_COURSES_SUCCESS,
  payload: { courses } 
});

export const fetchPrevNextCourseFailure = error => ({
  type: FETCH_PREV_NEXT_COURSES_FAILURE,
  payload: { error }
});

export const fetchUserCourseProgressBegin = () => ({
  type: FETCH_USER_COURSES_PROGRESS_BEGIN
});

export const fetchUserCourseProgressSuccess = progress => ({
  type: FETCH_USER_COURSES_PROGRESS_SUCCESS,
  payload: { progress } 
});

export const fetchUserCourseProgressFailure = error => ({
  type: FETCH_USER_COURSES_PROGRESS_FAILURE,
  payload: { error }
});

export const fetchUserIndividualCourseCompletionBegin = () => ({
  type: FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_BEGIN
});

export const fetchUserIndividualCourseCompletionSuccess = progress => ({
  type: FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_SUCCESS,
  payload: { progress } 
});

export const fetchUserIndividualCourseCompletionFailure = error => ({
  type: FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_FAILURE,
  payload: { error }
});


export const setProgressBegin = () => ({
  type: SET_PROGRESS_DONE_BEGIN
});

export const setProgressSuccess = progress => ({
  type: SET_PROGRESS_DONE_SUCCESS,
  payload: { progress } 
});

export const setProgressFailure = error => ({
  type: FETCH_COURSE_FAILURE,
  payload: { error }
});


const port = ""
//const port = "http://localhost:9000"

export function fetchCourse(slug) {
  return async dispatch => {

      let url = port+"/get-course?slug="+slug
      dispatch(fetchCourseBegin());
      let response = await APICall(url,null,"GET")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(fetchCourseSuccess(response.body.payload));
              break
            }
            default:{
              dispatch(fetchCourseFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(fetchCourseFailure(response.error))
      }



  };
}


export function fetchCourses(start,count,searching=null,appending=false) {
  return async dispatch => {
      let url = port+"/get-courses?start="+start+"&count="+count
      if(searching!==null){
        url += "&search="+searching
      }

      switch(appending){
        case true:{
          dispatch(fetchMoreCoursesBegin());
          let response = await APICall(url,null,"GET")
          if(response.body!=null){
            switch(response.body.code){
              case 200:{
                dispatch(fetchMoreCoursesSuccess(response.body));
                break;
              }
              default:{
                dispatch(fetchMoreCoursesFailure(response.body.message))
                break
              }
            }
          }else{
            dispatch(fetchMoreCoursesFailure(response.error))
          }

          break
        }
        case false:{
          dispatch(fetchCoursesBegin());
          let response = await APICall(url,null,"GET")
          if(response.body!=null){
            switch(response.body.code){
              case 200:{
                dispatch(fetchCoursesSuccess(response.body.payload));
                break;
              }
              default:{
                dispatch(fetchCoursesFailure(response.body.message))
                break
              }
            }
          }else{
            dispatch(fetchCoursesFailure(response.error))
          }
          break
        }
        default:
          break
      }

  };
}

export function fetchLatestCourse(){
    return async dispatch => {

      let url = port+"/get-courses?latest=true"
      dispatch(fetchLatestCourseBegin());
      let response = await APICall(url,null,"GET")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(fetchLatestCourseSuccess(response.body.payload));
              break
            }
            default:{
              dispatch(fetchLatestCourseFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(fetchLatestCourseFailure(response.error))
      }

  };

}

export function fetchUserCourseProgress(user,courses){
    return async dispatch => {
      return null

      /*
      let url = port+"/get-progress?user="+user+"&multiple-courses="+courses

      dispatch(fetchUserCourseProgressBegin());
      let response = await APICall(url,null,"GET")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(fetchUserCourseProgressSuccess(response.body.payload));
              break
            }
            default:{
              dispatch(fetchUserCourseProgressFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(fetchUserCourseProgressFailure(response.error))
      }
      */

  };

}

export function fetchUserIndividualCourseProgress(user,course,start,count){
    return async dispatch => {
      return null

      /*
      let url = port+"/get-progress?user="+user+"&course="+course+"&start="+start+"&count="+count

      dispatch(fetchUserIndividualCourseCompletionBegin());
      let response = await APICall(url,null,"GET")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(fetchUserIndividualCourseCompletionSuccess(response.body.payload));
              break
            }
            default:{
              dispatch(fetchUserIndividualCourseCompletionFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(fetchUserIndividualCourseCompletionFailure(response.error))
      }
      */

  };

}

export function setProgress(user,course,module){
    return async dispatch => {
      return null
      /*
      let url = port+"/record-progress"
      const payload = {"user":user,"course":course,"module":module}

      dispatch(setProgressBegin());
      let response = await APICall(url,payload,"POST")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(setProgressSuccess(response.body.payload));
              break
            }
            default:{
              dispatch(setProgressFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(setProgressFailure(response.error))
      }

      */

  }
  
}