import {
  FETCH_ACCOUNTS_BEGIN,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILURE,

  FETCH_MORE_ACCOUNTS_BEGIN,
  FETCH_MORE_ACCOUNTS_SUCCESS,
  FETCH_MORE_ACCOUNTS_FAILURE,

  FETCH_ACCOUNT_BEGIN,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILURE,

  ADD_ACCOUNT_BEGIN,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_FAILURE,

  EDIT_ACCOUNT_BEGIN,
  EDIT_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_FAILURE,

  ACCOUNT_LOGIN_BEGIN,
  ACCOUNT_LOGIN_SUCCESS,
  ACCOUNT_LOGIN_FAILURE,

  DELETE_ACCOUNT_BEGIN,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,

  RESET_ERROR_MESSAGE

} from '../actions/accountsActions.js';

const initialState = {
  accounts: [],
  selectedAccount: null,
  loggedIn: 1,
  loggedInName: null,
  targetInterface: null,
  loading: false,
  error: null,
  success: false,
  lastPullCount: 0
};

export default function accountsReducer(state = initialState, action) {
  switch(action.type) {
    case RESET_ERROR_MESSAGE:
      return{
        ...state,
        error:null,
        success:false,
      }
      
    case FETCH_ACCOUNTS_BEGIN:
      return {
        ...state,
        accounts:[],
        loading: true,
        error: null
      };

    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAccount: null,
        lastPullCount: action.payload.accounts.payload===null ? 0 : action.payload.accounts.payload.length,
        accounts: action.payload.accounts.payload === null ? [] : action.payload.accounts.payload
      };

    case FETCH_ACCOUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        selectedAccount: null,
        success:false,
        error: action.payload.error,
        accounts: []
      };



    case FETCH_MORE_ACCOUNTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_MORE_ACCOUNTS_SUCCESS:
      if(action.payload.accounts.payload!==null){
          let existingAccounts = state.accounts.slice()
          existingAccounts = existingAccounts.concat(action.payload.accounts.payload)
          return {
            ...state,
            loading: false,
            accounts: existingAccounts,
            lastPullCount: action.payload.accounts.payload===null ? 0 : action.payload.accounts.payload.length,
          };        
      }else{
          return {
            ...state,
            loading: false,
            lastPullCount:0
          };        
      }

    case FETCH_MORE_ACCOUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
        accounts: []
      };


    case FETCH_ACCOUNT_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_ACCOUNT_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        selectedAccount: action.payload.account,
        loading: false,
      };

    case FETCH_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
      };


    case ADD_ACCOUNT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ADD_ACCOUNT_SUCCESS:

      return {
        ...state,
        selectedAccount: null,
        loading: false,
        success: true,
        error: "A new user has been added succesfully"
      };

    case ADD_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };

    case EDIT_ACCOUNT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case EDIT_ACCOUNT_SUCCESS:

      let accounts = state.accounts.slice()

      for(let x = 0 ; x < accounts.length ; x++){
        if(accounts[x].ID===action.payload.account.ID){
            accounts[x] = action.payload.account
        }
      }

      return {
        ...state,
        accounts: accounts,
        loading: false,
        success: true,
        error: "User account edited succesfully"
      };

    case EDIT_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };

    case ACCOUNT_LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ACCOUNT_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error:null,
        loggedIn: action.payload.account.ID !== undefined ? action.payload.account.ID : null,
        loggedInName: action.payload.account.name !== undefined ? action.payload.account.name : null,
        targetInterface: action.payload.account.interface !== undefined ?  action.payload.account.interface : null
      };

    case ACCOUNT_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
        accounts: []
      };

    case DELETE_ACCOUNT_BEGIN:
      return{
        ...state
      };

    case DELETE_ACCOUNT_SUCCESS:
      let tmp = state.accounts.slice()
      for( let x = 0 ;x < tmp.length ; x++){
        if(tmp[x].ID===action.payload.account.ID){
          tmp.splice(x,1)
        }
      }

      return{
        ...state,
        accounts:tmp        
      }

    case DELETE_ACCOUNT_FAILURE:
      return{
        ...state,
        success:false,
        error:action.payload.error
      }


    default:
      return state;
  }
}