import {
  FETCH_COURSES_BEGIN,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_FAILURE,

  FETCH_MORE_COURSES_BEGIN,
  FETCH_MORE_COURSES_SUCCESS,
  FETCH_MORE_COURSES_FAILURE,

  FETCH_COURSE_BEGIN,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_FAILURE,

  FETCH_LATEST_COURSE_BEGIN,
  FETCH_LATEST_COURSE_SUCCESS,
  FETCH_LATEST_COURSE_FAILURE,

  FETCH_USER_COURSES_PROGRESS_BEGIN,
  FETCH_USER_COURSES_PROGRESS_SUCCESS,
  FETCH_USER_COURSES_PROGRESS_FAILURE,

  FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_BEGIN,
  FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_SUCCESS,
  FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_FAILURE,

  SET_PROGRESS_DONE_BEGIN,
  SET_PROGRESS_DONE_SUCCESS, 
  SET_PROGRESS_DONE_FAILURE 


} from '../actions/coursesActions.js';

const initialState = {
  courses: [],
  courseProgress: [],
  individualCompletedModules:[],
  selectedCourse: null,
  latestCourse: null,

  loading: false,
  error: null,
  success: false,
  lastPullCount: 0,

  latestCourseLoading: false,
  latestCourseError: null,

  markingDone:false
};

export default function coursesReducer(state = initialState, action) {
  let tmp = null
  switch(action.type) {
      
    case FETCH_COURSES_BEGIN:
      return {
        ...state,
        courses:[],
        loading: true,
        error: null
      };

    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCourse: null,
        lastPullCount: action.payload.courses === null ? 0 : action.payload.courses.length,
        courses: action.payload.courses === null ? [] : action.payload.courses
      };

    case FETCH_COURSES_FAILURE:
      return {
        ...state,
        loading: false,
        selectedAccount: null,
        success:false,
        error: action.payload.error,
        courses: []
      };



    case FETCH_MORE_COURSES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_MORE_COURSES_SUCCESS:
      if(action.payload.courses.payload!==null){
          let existingAccounts = state.courses.slice()
          existingAccounts = existingAccounts.concat(action.payload.courses.payload)
          return {
            ...state,
            loading: false,
            courses: existingAccounts,
            lastPullCount: action.payload.courses.payload===null ? 0 : action.payload.courses.payload.length,
          };        
      }else{
          return {
            ...state,
            loading: false,
            lastPullCount:0
          };        
      }

    case FETCH_MORE_COURSES_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
        courses: []
      };


    case FETCH_COURSE_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_COURSE_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        selectedCourse: action.payload.course,
        loading: false,
      };

    case FETCH_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
      };


    case FETCH_LATEST_COURSE_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        latestCourseLoading: true,
        latestCourseError: null
      };

    case FETCH_LATEST_COURSE_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        latestCourse: action.payload.course,
        latestCourseLoading: false,
      };

    case FETCH_LATEST_COURSE_FAILURE:
      return {
        ...state,
        latestCourseLoading: false,
        success:false,
        latestCourseError: action.payload.error,
      };

    case FETCH_USER_COURSES_PROGRESS_BEGIN:
      return{
        ...state,
      }
    
    case FETCH_USER_COURSES_PROGRESS_SUCCESS:
//      let tmp = state.courseProgress
//      tmp = tmp.concat(tmp,action.payload.)
      return{
        ...state,
        courseProgress: state.courseProgress.concat(action.payload.progress)
      }

    case FETCH_USER_COURSES_PROGRESS_FAILURE:
      return{
        ...state
      }


    case FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_BEGIN:
      return {
        ...state,
        individualCompletedModules:[]
      }
    case FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_SUCCESS:
      return{
        ...state,
        individualCompletedModules: state.individualCompletedModules.concat(action.payload.progress)
      }

    case FETCH_USER_INDIVIDUAL_COURSE_COMPLETION_FAILURE:
      return{
        ...state
      }


    case SET_PROGRESS_DONE_BEGIN:
      return{
        ...state,
        markingDone:true
      }

    case SET_PROGRESS_DONE_SUCCESS:
      tmp = state.courseProgress.slice()
      for(let x = 0 ; x < tmp.length ; x++){
        if(tmp[x].course===action.payload.progress.course){
          tmp[x].modules_covered++
        }
      }

      let tmp_ = state.individualCompletedModules.slice()
      tmp_.push(action.payload.progress)

      return{
        ...state,
        courseProgress:tmp,
        individualCompletedModules: tmp_,       
        markingDone:false
      }

    case SET_PROGRESS_DONE_FAILURE:{
      return{
        ...state,
        markingDone:false
      }
    }


    default:
      return state;
  }
}