import React from 'react';
import {connect} from "react-redux";

import {Courses,TitleStrip,PullMore,Loading,Error} from "./Embeds/Common.js"
import {fetchCourses,fetchUserCourseProgress} from "../actions/coursesActions"
import {switchURLS,setActiveMenu} from "../actions/commonActions"

import {APICallCount,CommonTitle,SetHeaderColorCode,Analytics} from "../API"

import {CoursesLabel} from "../WebCopy.js";

const mapStateToProps = (state) => {
  return {
    loading: state.coursesReducer.loading,
    error: state.coursesReducer.error,
    success: state.coursesReducer.success,
    courses: state.coursesReducer.courses, 
    courseProgress: state.coursesReducer.courseProgress,
    lastPullCount: state.coursesReducer.lastPullCount,

    loggedIn: state.accountsReducer.loggedIn    
  };
}

const mapDispatchToProps = {
  fetchCourses,
  fetchUserCourseProgress,
  switchURLS,
  setActiveMenu  
}


class CoursesPage extends React.Component{
  constructor(props){
    super(props)
    //this will have a list of courses we need to find progress for for a given user
    this.gatherProgressFor = []
    this.courses = <Loading />
    this.start = 0
    /*
    we are commenting this out as we wont use breadcrumbs any more
    this.breadCrumbs = [];
    let link = {}
    link["name"] = CoursesLabel()
    if(getLanguage()==="en"){
      link["path"] = "/courses"
    }else{
      link["path"] = "/kozi"
    }

    this.breadCrumbs.push(link);      
    */
    this.append = false
  }

  setTitle = () =>{
    window.scrollTo(0,0)
    SetHeaderColorCode()
    document.title = CoursesLabel() + " | " + CommonTitle
    Analytics()
    this.props.setActiveMenu("courses")
  }

  fetchCourses = () =>{
      this.props.fetchCourses(this.start,APICallCount,null,this.append)
  }

  pullMore = () =>{
    this.append = true
    this.start = this.props.courses.length
    this.fetchCourses() 
  }

  componentDidMount(){
      this.setTitle()
      if(this.props.courses.length<APICallCount){
        this.fetchCourses()
      }

      this.props.switchURLS({"en":"/courses","sw":"/kozi"})
   }


  processCourses = () =>{
    if(this.props.loading===true){
      return
    }

    if(this.props.error!==null && this.props.courses.length===0){
        this.courses = <Error Message={this.props.error} />
        return
    }

    let found = false
    let newIds = ""
    for(let x = 0 ; x < this.props.courses.length ; x++){

        found = false
        for(let y = 0 ; y < this.props.courseProgress.length ; y++){

            if(this.props.courseProgress[y] !== null && this.props.courseProgress[y].ID === this.props.courses[x].ID){
                found = true
                break
            }

        }

        if(found===false){

            if( this.gatherProgressFor.includes(this.props.courses[x].ID)===false ){
                this.gatherProgressFor.push( this.props.courses[x].ID )


                if(newIds===""){
                  newIds = this.props.courses[x].ID
                }else{
                  newIds += ","+this.props.courses[x].ID
                }

            }  

        }

    }


    if(this.props.loggedIn!==null && newIds!==""){
      this.props.fetchUserCourseProgress(this.props.loggedIn,newIds)
    }

    this.courses = <Courses 
            Courses={this.props.courses} 
            CoursesProgress={this.props.courseProgress}
            home={false}
        />
  }

	render(){
    this.processCourses()

		return (
      <>
        <TitleStrip title={CoursesLabel()} 
        />
        <div className="row noMargin">
          <div className="container">
            {this.courses}

            {
              this.props.lastPullCount === APICallCount ? 
                <PullMore 
                  action={this.pullMore}
                  loading={this.props.loading}
                /> : null
            }
            
          </div>
        </div>
      </>
		)		
	}

}

const comp = connect(mapStateToProps,mapDispatchToProps)(CoursesPage);
export default comp;
