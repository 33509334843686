import {
  FETCH_MODULES_BEGIN,
  FETCH_MODULES_SUCCESS,
  FETCH_MODULES_FAILURE,

  FETCH_MORE_MODULES_BEGIN,
  FETCH_MORE_MODULES_SUCCESS,
  FETCH_MORE_MODULES_FAILURE,

  FETCH_ADJACENT_MODULES_BEGIN,
  FETCH_ADJACENT_MODULES_SUCCESS,
  FETCH_ADJACENT_MODULES_FAILURE,

  FETCH_MODULE_BEGIN,
  FETCH_MODULE_SUCCESS,
  FETCH_MODULE_FAILURE,

  ADD_MODULE_BEGIN,
  ADD_MODULE_SUCCESS,
  ADD_MODULE_FAILURE,

  EDIT_MODULE_BEGIN,
  EDIT_MODULE_SUCCESS,
  EDIT_MODULE_FAILURE,

  DELETE_MODULE_BEGIN,
  DELETE_MODULE_SUCCESS,
  DELETE_MODULE_FAILURE,

  TOGGLE_MODULE_BEGIN,
  TOGGLE_MODULE_SUCCESS,
  TOGGLE_MODULE_FAILURE,

} from '../actions/modulesActions.js';

const initialState = {
  modules: [],
  adjacentModules:[],
  selectedModule: null,
  loading: false,
  error: null,
  success: false,
  lastPullCount: 0
};

export default function modulesReducer(state = initialState, action) {
  switch(action.type) {
      
    case FETCH_MODULES_BEGIN:
      return {
        ...state,
        modules:[],
        loading: true,
        error: null
      };

    case FETCH_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedModule: null,
        lastPullCount: action.payload.modules === null ? 0 : action.payload.modules.length,
        modules: action.payload.modules === null ? [] : action.payload.modules
      };

    case FETCH_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        selectedModule: null,
        success:false,
        error: action.payload.error,
        modules: []
      };



    case FETCH_MORE_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_MORE_MODULES_SUCCESS:
      if(action.payload.modules!==null){
          let existingAccounts = state.modules.slice()
          existingAccounts = existingAccounts.concat(action.payload.modules)
          return {
            ...state,
            loading: false,
            modules: existingAccounts,
            lastPullCount: action.payload.modules===null ? 0 : action.payload.modules.length,
          };        
      }else{
          return {
            ...state,
            loading: false,
            lastPullCount:0
          };        
      }

    case FETCH_MORE_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
        modules: []
      };


    case FETCH_MODULE_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        selectedModule:null,
        loading: true,
        error: null
      };

    case FETCH_MODULE_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        selectedModule: action.payload.module,
        loading: false,
      };

    case FETCH_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
      };


    case ADD_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ADD_MODULE_SUCCESS:

      return {
        ...state,
        selectedModule: action.payload.module,
        loading: false,
        success: true,
        error: "A new module has been added succesfully"
      };

    case ADD_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };

    case EDIT_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case EDIT_MODULE_SUCCESS:


      return {
        ...state,
        loading: false,
        success: true,
        error: "Module edited succesfully"
      };

    case EDIT_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };

    case TOGGLE_MODULE_BEGIN:
      return{
        ...state,
        loading:true
      }

    case TOGGLE_MODULE_SUCCESS:
      let tmp1 = state.modules.slice()
      for( let x = 0 ;x < tmp1.length ; x++){
        if(tmp1[x].ID===action.payload.module.ID){
          tmp1[x]=action.payload.module
        }
      }

      return{
        ...state,
        loading:false,
        modules:tmp1
      }

    case TOGGLE_MODULE_FAILURE:
      return{
        ...state,
        loading:false,
        error:action.payload.error
      }


    case DELETE_MODULE_BEGIN:
      return{
        ...state
      };

    case DELETE_MODULE_SUCCESS:
      let tmp = state.modules.slice()
      for( let x = 0 ;x < tmp.length ; x++){
        if(tmp[x].ID===action.payload.module.id){
          tmp.splice(x,1)
        }
      }
      return{
        ...state,
        modules:tmp        
      }

    case DELETE_MODULE_FAILURE:
      return{
        ...state,
        success:false,
        error:action.payload.error
      }

    case FETCH_ADJACENT_MODULES_BEGIN:
      return{
        ...state,
        adjacentModules:[]
      }

    case FETCH_ADJACENT_MODULES_SUCCESS:
      return{
        ...state,
        adjacentModules:action.payload.modules.slice()
      }

    case FETCH_ADJACENT_MODULES_FAILURE:
      return{
        ...state
      }

    default:
      return state;
  }
}