import {getLanguage} from "./API.js"

export function HomeLabel(){
  return getLanguage() === "en" ? "Home" : "Mwanzo"
}

export function AboutUsLabel(){
    return getLanguage() === "en" ? "About Us" : "Kuhusu"
}

export function CoursesLabel(){
    return getLanguage() === "en" ? "Courses" : "Kozi"
}

export function ResourcesLabel(){
    return getLanguage() === "en" ? "Worksheets" : "Rasilimali"
}

export function SignInLabel(){
    return getLanguage() === "en" ? "Sign In" : "Ingia"
}

export function SignUpLabel(){
    return getLanguage() === "en" ? "Sign Up" : "Jiunge"
}

export function EnglishLabel(){
    return getLanguage() === "en" ? "English" : "Kingereza"
}

export function SwahiliLabel(){
    return getLanguage() === "en" ? "Swahili" : "Kiswahili"
}

export function WatchHereLabel(){
    return getLanguage() === "en" ? "Watch Here" : "Angalia"
}

export function FooterNotesLabel(){ 
  const year = new Date().getFullYear()
  return getLanguage() === "en" ? year+" TBWA/KhangaRue Media. All Rights Reserved" : year+" TBWA/KhangaRue Media. Haki Zote Zimehifadhiwa"
}

export function LatestCourseLabel(){
  return getLanguage() === "en" ? "Latest Course" : "Kozi Mpya"
}

export function ViewCourseLabel(){
  return getLanguage() === "en" ? "View Course" : "Angalia "
}

export function AboutNoaUbongoLabel(){
  return getLanguage() === "en" ? "ABOUT <br/> NOA UBONGO" : "KUHUSU <br/> NOA UBONGO"
}

export function CompletedLabel(){
  return getLanguage() === "en" ? "Completed" : "Umeimaliza"
}

export function PullMoreLabel(){
  return getLanguage() === "en" ? "More" : "Ongeza"
}

export function AdjacentLesson(prevNext){
  if(prevNext==="previous"){
    return getLanguage() === "en" ? "Previous Lesson" : "Somo Lililopita"
  }else{
    return getLanguage() === "en" ? "Next Lesson" : "Somo Lifuatalo"    
  }

}

export function CompletedValueLabel(){
    return getLanguage() === "en" ? "Completed " : "Umemaliza "      
}

export function CourseTitle(){
    return getLanguage() === "en" ? "Course title " : "Jina la kozi "        
}

export function CourseModulesLabel(){
    return getLanguage() === "en" ? "Course Lessons " : "Matoleo ya Kozi "        
}

export function SupportingContentLabel(){
  return getLanguage() === "en" ? "Supporting Content" : "Rasilimali"
}

export function LessonCompleteLabel(){
  return getLanguage() === "en" ? "Lesson Complete" : "Umemaliza Somo"
}

export function MarkCompleteLabel(){
  return getLanguage() === "en" ? "Mark Complete" : "Nimemaliza Somo"
}

export function AnErrorOccuredLabel(){
  return getLanguage() === "en" ? "An error occured" : "Kuna tatizo limetokea"
}

export function PageDoesNotExistError(){
  return getLanguage() === "en" ? "We could not get content for the page you are visiting" : "Tumeshindwa kupata maudhui ya ukurasa uliotembelea"
}

export function LoginSignUp(){
  return getLanguage() === "en" ? "Please select an option to use below to login to  the system" : "Tafadhali chagua njia unayotaka kuingia kwenye mfumo"
}

export function LoginSignUpTitle(){
  return getLanguage() === "en" ? "Sign In / Sign Up" : "Ingia / Jiunge"
}

export function CourseLabel(){
  return getLanguage() === "en" ? "Course" : "Kozi"
}

export function ModuleLabel(){
  return getLanguage() === "en" ? "Lesson" : "Toleo"  
}

export function Name(){
    return getLanguage() === "en" ? "Name" : "Jina"
}

export function DownloadLabel(){
  return getLanguage() === "en" ? "Download" :"Pakua"
}

export function ViewAllCoursesLabel(){
  return getLanguage() === "en" ? "View all courses" : "Peruzi kozi zote"
}

export function signOut(){
  return getLanguage() === "en" ? "Sign Out" : "Ondoka"
}

export function EpisodeLabel(){
  return getLanguage() === "en" ? "EP " : "SH"
}

export function ContactUs(){
  return getLanguage() === "en" ? "For more information: info@khangarue.com" : "Kwa maelezo zaidi: info@khangarue.com"
}