import {APICall,setCookie,getCookie,logout} from "../API.js"

export const FETCH_ACCOUNTS_BEGIN   = 'FETCH_ACCOUNTS_BEGIN';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAILURE = 'FETCH_ACCOUNTS_FAILURE';

export const FETCH_MORE_ACCOUNTS_BEGIN   = 'FETCH_MORE_ACCOUNTS_BEGIN';
export const FETCH_MORE_ACCOUNTS_SUCCESS = 'FETCH_MORE_ACCOUNTS_SUCCESS';
export const FETCH_MORE_ACCOUNTS_FAILURE = 'FETCH_MORE_ACCOUNTS_FAILURE';

export const FETCH_ACCOUNT_BEGIN   = 'FETCH_ACCOUNT_BEGIN';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_FAILURE = 'FETCH_ACCOUNT_FAILURE';

export const ADD_ACCOUNT_BEGIN   = 'ADD_ACCOUNT_BEGIN';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_FAILURE = 'ADD_ACCOUNT_FAILURE';

export const EDIT_ACCOUNT_BEGIN   = 'EDIT_ACCOUNT_BEGIN';
export const EDIT_ACCOUNT_SUCCESS = 'EDIT_ACCOUNT_SUCCESS';
export const EDIT_ACCOUNT_FAILURE = 'EDIT_ACCOUNT_FAILURE';

export const DELETE_ACCOUNT_BEGIN   = 'DELETE_ACCOUNT_BEGIN';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const ACCOUNT_LOGIN_BEGIN   = 'ACCOUNT_LOGIN_BEGIN';
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS';
export const ACCOUNT_LOGIN_FAILURE = 'ACCOUNT_LOGIN_FAILURE';

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE'

export const resetErrorMessage = () => ({
  type: RESET_ERROR_MESSAGE
});

export const fetchAccountsBegin = () => ({
  type: FETCH_ACCOUNTS_BEGIN
});

export const fetchAccountsSuccess = accounts => ({
  type: FETCH_ACCOUNTS_SUCCESS,
  payload: { accounts }
});

export const fetchAccountsFailure = error => ({
  type: FETCH_ACCOUNTS_FAILURE,
  payload: { error }
});


export const fetchMoreAccountsBegin = () => ({
  type: FETCH_MORE_ACCOUNTS_BEGIN
});

export const fetchMoreAccountsSuccess = accounts => ({
  type: FETCH_MORE_ACCOUNTS_SUCCESS,
  payload: { accounts }
});

export const fetchMoreAccountsFailure = error => ({
  type: FETCH_MORE_ACCOUNTS_FAILURE,
  payload: { error }
});


export const fetchAccountBegin = () => ({
  type: FETCH_ACCOUNT_BEGIN
});

export const fetchAccountSuccess = account => ({
  type: FETCH_ACCOUNT_SUCCESS,
  payload: {account} 
});

export const fetchAccountFailure = error => ({
  type: FETCH_ACCOUNT_FAILURE,
  payload: { error }
});


export const addAccountBegin = () => ({
  type: ADD_ACCOUNT_BEGIN
});

export const addAccountSuccess = account => ({
  type: ADD_ACCOUNT_SUCCESS,
  payload: { account } 
});

export const addAccountFailure = error => ({
  type: ADD_ACCOUNT_FAILURE,
  payload: { error }
});


export const editAccountBegin = () => ({
  type: EDIT_ACCOUNT_BEGIN
});

export const editAccountSuccess = account => ({
  type: EDIT_ACCOUNT_SUCCESS,
  payload: { account } 
});

export const editAccountFailure = error => ({
  type: EDIT_ACCOUNT_FAILURE,
  payload: { error }
});

export const accountLoginBegin = () => ({
  type: ACCOUNT_LOGIN_BEGIN,
});

export const accountLoginSuccess = account => ({
  type: ACCOUNT_LOGIN_SUCCESS,
  payload: { account }
});

export const accountLoginFailure = error => ({
  type: ACCOUNT_LOGIN_FAILURE,
  payload: { error }
});

export const deleteAccountBegin = () => ({
  type: DELETE_ACCOUNT_BEGIN
});

export const deleteAccountSuccess = account => ({
  type: DELETE_ACCOUNT_SUCCESS,
  payload: { account } 
});

export const deleteAccountFailure = error => ({
  type: DELETE_ACCOUNT_FAILURE,
  payload: { error }
});

export function resetErrors(){
  return async dispatch => {
      dispatch(resetErrorMessage());
  }
}
const port = ""
//const port = "http://localhost:9000"
export function addAccount(account,callback){
  console.log(account)
  return async dispatch => {
      dispatch(addAccountBegin());
      let response = await APICall(port+"/add-user",account,"POST")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(addAccountSuccess(account));
              callback(account)
              break
            }
            default:{
              dispatch(addAccountFailure(response.body.message))
            }
          }
      }else{
        dispatch(addAccountFailure(response.error))
      }
  };

}

export function editAccount(account){
  return async dispatch => {
      account.updated_by = parseInt(getCookie("loggedIn"))
      dispatch(editAccountBegin());
      let response = await APICall(port+"/edit-user",account,"POST")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(editAccountSuccess(account));
              break
            }
            default:{
              dispatch(editAccountFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(editAccountFailure(response.error))
      }
  };

}

export function toggleAccount(account){
  return async dispatch => {
      account.updated_by = parseInt(getCookie("loggedIn"))
//      dispatch(editAccountBegin());
      let response = await APICall(port+"/edit-user-status",account,"POST")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(editAccountSuccess(account));
              break
            }
            default:{
              dispatch(editAccountFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(editAccountFailure(response.error))
      }
  };

}

export function deleteAccount(account){
  return async dispatch => {
      account.updated_by = parseInt(getCookie("loggedIn"))
      
      dispatch(deleteAccountBegin());
      let response = await APICall(port+"/delete-user",account,"POST")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(deleteAccountSuccess(account));
              break
            }
            default:{
              dispatch(deleteAccountFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(deleteAccountFailure(response.error))
      }
  };

}

export function fetchAccount(userId) {
  return async dispatch => {

      let url = port+"/get-user?userId="+userId
      dispatch(fetchAccountBegin());
      let response = await APICall(url,null,"GET")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(fetchAccountSuccess(response.body.payload));
              break
            }
            default:{
              dispatch(fetchAccountFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(fetchAccountFailure(response.error))
      }



  };
}


export function fetchAccounts(start,count,searching=null,category=null,userType=1,appending=false) {
  return async dispatch => {

      let url = port+"/get-users?start="+start+"&count="+count+"&admin=true&type="+userType
      if(searching!==null){
        url += "&search="+searching
      }
      if(category!==null){
        url += "&category="+category            
      }


      switch(appending){
        case true:{
          dispatch(fetchMoreAccountsBegin());
          let response = await APICall(url,null,"GET")
          if(response.body!=null){
            switch(response.body.code){
              case 200:{
                dispatch(fetchMoreAccountsSuccess(response.body));
                break;
              }
              default:{
                dispatch(fetchMoreAccountsFailure(response.body.message))
                break
              }
            }
          }else{
            dispatch(fetchMoreAccountsFailure(response.error))
          }

          break
        }
        case false:{
          dispatch(fetchAccountsBegin());
          let response = await APICall(url,null,"GET")
          if(response.body!=null){
            switch(response.body.code){
              case 200:{
                dispatch(fetchAccountsSuccess(response.body));
                break;
              }
              default:{
                dispatch(fetchAccountsFailure(response.body.message))
                break
              }
            }
          }else{
            dispatch(fetchAccountsFailure(response.error))
          }
          break
        }
        default:
          break
      }

  };
}

export function accountLogin(loginId,loginPassword,action="login",callback) {

  return async dispatch => {

      let response = {body:null,error:null}
      if(action==="logout"){
          response.body = {}
          logout()
          dispatch(accountLoginSuccess(response.body));
          return
      }

      dispatch(accountLoginBegin());
      const userCredentials = {"login_key":loginId,"password":loginPassword}

      response = await APICall(port+"/admin-login",userCredentials,"POST")
      if(callback!==undefined){
          callback(response.body)        
      }

      if(response.body!=null){
          if(response.body.code!==200){
            dispatch(accountLoginFailure(response.body.message))
            return
          }

          setCookie("loggedIn",response.body.payload.ID)
          setCookie("loggedInName",response.body.payload.name)
          setCookie("access-token",response.body.payload.token)
          dispatch(accountLoginSuccess(response.body.payload));
      }else{
        dispatch(accountLoginFailure(response.error))
      }

  };  

}


