import {APICall} from "../API.js"

export const FETCH_MODULE_BEGIN   = 'FETCH_MODULE_BEGIN';
export const FETCH_MODULE_SUCCESS = 'FETCH_MODULE_SUCCESS';
export const FETCH_MODULE_FAILURE = 'FETCH_MODULE_FAILURE';

export const FETCH_MORE_MODULES_BEGIN   = 'FETCH_MORE_MODULES_BEGIN';
export const FETCH_MORE_MODULES_SUCCESS = 'FETCH_MORE_MODULES_SUCCESS';
export const FETCH_MORE_MODULES_FAILURE = 'FETCH_MORE_MODULES_FAILURE';

export const FETCH_MODULES_BEGIN   = 'FETCH_MODULES_BEGIN';
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS';
export const FETCH_MODULES_FAILURE = 'FETCH_MODULES_FAILURE';

export const FETCH_ADJACENT_MODULES_BEGIN   = 'FETCH_ADJACENT_MODULES_BEGIN';
export const FETCH_ADJACENT_MODULES_SUCCESS = 'FETCH_ADJACENT_MODULES_SUCCESS';
export const FETCH_ADJACENT_MODULES_FAILURE = 'FETCH_ADJACENT_MODULES_FAILURE';

export const ADD_MODULE_BEGIN   = 'ADD_MODULE_BEGIN';
export const ADD_MODULE_SUCCESS = 'ADD_MODULE_SUCCESS';
export const ADD_MODULE_FAILURE = 'ADD_MODULE_FAILURE';

export const EDIT_MODULE_BEGIN   = 'EDIT_MODULE_BEGIN';
export const EDIT_MODULE_SUCCESS = 'EDIT_MODULE_SUCCESS';
export const EDIT_MODULE_FAILURE = 'EDIT_MODULE_FAILURE';

export const DELETE_MODULE_BEGIN   = 'DELETE_MODULE_BEGIN';
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS';
export const DELETE_MODULE_FAILURE = 'DELETE_MODULE_FAILURE';

export const ACCOUNT_LOGIN_BEGIN   = 'ACCOUNT_LOGIN_BEGIN';
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS';
export const ACCOUNT_LOGIN_FAILURE = 'ACCOUNT_LOGIN_FAILURE';

export const TOGGLE_MODULE_BEGIN   = 'TOGGLE_MODULE_BEGIN';
export const TOGGLE_MODULE_SUCCESS = 'TOGGLE_MODULE_SUCCESS';
export const TOGGLE_MODULE_FAILURE = 'TOGGLE_MODULE_FAILURE';


export const toggleModuleBegin = () => ({
  type: TOGGLE_MODULE_BEGIN
});

export const toggleModuleSuccess = module => ({
  type: TOGGLE_MODULE_SUCCESS,
  payload: { module }
});

export const toggleModuleFailure = error => ({
  type: TOGGLE_MODULE_FAILURE,
  payload: { error }
});

export const fetchModulesBegin = () => ({
  type: FETCH_MODULES_BEGIN
});

export const fetchModulesSuccess = modules => ({
  type: FETCH_MODULES_SUCCESS,
  payload: { modules }
});

export const fetchModulesFailure = error => ({
  type: FETCH_MODULES_FAILURE,
  payload: { error }
});

export const fetchMoreModulesBegin = () => ({
  type: FETCH_MORE_MODULES_BEGIN
});

export const fetchMoreModulesSuccess = modules => ({
  type: FETCH_MORE_MODULES_SUCCESS,
  payload: { modules }
});

export const fetchMoreModulesFailure = error => ({
  type: FETCH_MORE_MODULES_FAILURE,
  payload: { error }
});


export const fetchModuleBegin = () => ({
  type: FETCH_MODULE_BEGIN
});

export const fetchModuleSuccess = module => ({
  type: FETCH_MODULE_SUCCESS,
  payload: { module } 
});

export const fetchModuleFailure = error => ({
  type: FETCH_MODULE_FAILURE,
  payload: { error }
});


export const addModuleBegin = () => ({
  type: ADD_MODULE_BEGIN
});

export const addModuleSuccess = module => ({
  type: ADD_MODULE_SUCCESS,
  payload: { module } 
});

export const addModuleFailure = error => ({
  type: ADD_MODULE_FAILURE,
  payload: { error }
});


export const editModuleBegin = () => ({
  type: EDIT_MODULE_BEGIN
});

export const editModuleSuccess = module => ({
  type: EDIT_MODULE_SUCCESS,
  payload: { module } 
});

export const editModuleFailure = error => ({
  type: EDIT_MODULE_FAILURE,
  payload: { error }
});

export const deleteModuleBegin = () => ({
  type: DELETE_MODULE_BEGIN
});

export const deleteModuleSuccess = module => ({
  type: DELETE_MODULE_SUCCESS,
  payload: { module } 
});

export const deleteModuleFailure = error => ({
  type: DELETE_MODULE_FAILURE,
  payload: { error }
});


export const fetchAdjacentModulesBegin = () => ({
  type: FETCH_ADJACENT_MODULES_BEGIN
});

export const fetchAdjacentModulesSuccess = modules => ({
  type: FETCH_ADJACENT_MODULES_SUCCESS,
  payload: { modules }
});

export const fetchAdjacentModulesFailure = error => ({
  type: FETCH_ADJACENT_MODULES_FAILURE,
  payload: { error }
});



const port = ""
//const port = "http://localhost:9000"
export function addModule(module,callback){
  return async dispatch => {
      dispatch(addModuleBegin());
      let response = await APICall(port+"/add-module",module,"POST")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(addModuleSuccess(response.body.payload));
              callback()
              break
            }
            default:{
              dispatch(addModuleFailure(response.body.message))
            }
          }
      }else{
        dispatch(addModuleFailure(response.error))
      }
  };

}

export function editModule(module){
  return async dispatch => {
      dispatch(editModuleBegin());
      let response = await APICall(port+"/edit-module",module,"POST")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(editModuleSuccess(response.body.payload));
              break
            }
            default:{
              dispatch(editModuleFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(editModuleFailure(response.error))
      }
  };

}

export function toggleModule(module){
  return async dispatch => {
      let response = await APICall(port+"/update-module-status",module,"POST")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(toggleModuleSuccess(module));
              break
            }
            default:{
              dispatch(toggleModuleFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(toggleModuleFailure(response.error))
      }
  };

}

export function deleteModule(module){
  return async dispatch => {      
      dispatch(deleteModuleBegin());
      let response = await APICall(port+"/delete-module",module,"POST")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(deleteModuleSuccess(module));
              break
            }
            default:{
              dispatch(deleteModuleFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(deleteModuleFailure(response.error))
      }
  };

}

export function fetchModule(slug) {
  return async dispatch => {

      let url = port+"/get-module?slug="+slug
      dispatch(fetchModuleBegin());
      let response = await APICall(url,null,"GET")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(fetchModuleSuccess(response.body.payload));
              break
            }
            default:{
              dispatch(fetchModuleFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(fetchModuleFailure(response.error))
      }



  };
}


export function fetchModules(start,count,searching=null,course=null,appending=false) {
  return async dispatch => {

      let url = port+"/get-modules?start="+start+"&count="+count
      if(searching!==null){
          url += "&search="+searching
      }
      if(course!==null){
          url += "&course="+course
      }


      switch(appending){
        case true:{
          dispatch(fetchMoreModulesBegin());
          let response = await APICall(url,null,"GET")
          if(response.body!=null){
            switch(response.body.code){
              case 200:{
                dispatch(fetchMoreModulesSuccess(response.body.payload));
                break;
              }
              default:{
                dispatch(fetchMoreModulesFailure(response.body.message))
                break
              }
            }
          }else{
            dispatch(fetchMoreModulesFailure(response.error))
          }

          break
        }
        case false:{
          dispatch(fetchModulesBegin());
          let response = await APICall(url,null,"GET")
          if(response.body!=null){
            switch(response.body.code){
              case 200:{
                dispatch(fetchModulesSuccess(response.body.payload));
                break;
              }
              default:{
                dispatch(fetchModulesFailure(response.body.message))
                break
              }
            }
          }else{
            dispatch(fetchModulesFailure(response.error))
          }
          break
        }
        default:
          break
      }

  };
}

export function fetchAdjacentModules(course,currentModulePosition) {
  return async dispatch => {

      let url = port+"/get-adjacent-modules?course="+course+"&modulePosition="+currentModulePosition

      dispatch(fetchAdjacentModulesBegin());
      let response = await APICall(url,null,"GET")
      if(response.body!=null){
        switch(response.body.code){
          case 200:{
            dispatch(fetchAdjacentModulesSuccess(response.body.payload));
            break;
          }
          default:{
            dispatch(fetchAdjacentModulesFailure(response.body.message))
            break
          }
        }
      }else{
        dispatch(fetchAdjacentModulesFailure(response.error))
      }

  };
}
