import {APICall} from "../API.js"

export const FETCH_PAGE_BEGIN   = 'FETCH_PAGE_BEGIN';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_FAILURE = 'FETCH_PAGE_FAILURE';

export const FETCH_MORE_PAGES_BEGIN   = 'FETCH_MORE_PAGES_BEGIN';
export const FETCH_MORE_PAGES_SUCCESS = 'FETCH_MORE_PAGES_SUCCESS';
export const FETCH_MORE_PAGES_FAILURE = 'FETCH_MORE_PAGES_FAILURE';

export const FETCH_PAGES_BEGIN   = 'FETCH_PAGES_BEGIN';
export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS';
export const FETCH_PAGES_FAILURE = 'FETCH_PAGES_FAILURE';

export const fetchPagesBegin = () => ({
  type: FETCH_PAGES_BEGIN
});

export const fetchPagesSuccess = pages => ({
  type: FETCH_PAGES_SUCCESS,
  payload: { pages }
});

export const fetchPagesFailure = error => ({
  type: FETCH_PAGES_FAILURE,
  payload: { error }
});

export const fetchMorePagesBegin = () => ({
  type: FETCH_MORE_PAGES_BEGIN
});

export const fetchMorePagesSuccess = pages => ({
  type: FETCH_MORE_PAGES_SUCCESS,
  payload: { pages }
});

export const fetchMorePagesFailure = error => ({
  type: FETCH_MORE_PAGES_FAILURE,
  payload: { error }
});


export const fetchPageBegin = () => ({
  type: FETCH_PAGE_BEGIN
});

export const fetchPageSuccess = page => ({
  type: FETCH_PAGE_SUCCESS,
  payload: { page } 
});

export const fetchPageFailure = error => ({
  type: FETCH_PAGE_FAILURE,
  payload: { error }
});




const port = ""

export function fetchPage(slug,id) {
  return async dispatch => {

      let url = port+"/get-page"
      if(slug!==null) 
          url += "?slug="+slug
      else
          url+="?id="+id
        
      dispatch(fetchPageBegin());
      let response = await APICall(url,null,"GET")
      if(response.body!=null){
          switch(response.body.code){
            case 200:{
              dispatch(fetchPageSuccess(response.body.payload));
              break
            }
            default:{
              dispatch(fetchPageFailure(response.body.message))
              break
            }
          }
      }else{
        dispatch(fetchPageFailure(response.error))
      }

  };
}


export function fetchPages(start,count,searching=null,appending=false) {
  return async dispatch => {

      let url = port+"/get-pages?start="+start+"&count="+count+"&admin=true"
      if(searching!==null){
        url += "&search="+searching
      }


      switch(appending){
        case true:{
          dispatch(fetchMorePagesBegin());
          let response = await APICall(url,null,"GET")
          if(response.body!=null){
            switch(response.body.code){
              case 200:{
                dispatch(fetchMorePagesSuccess(response.body.payload));
                break;
              }
              default:{
                dispatch(fetchMorePagesFailure(response.body.message))
                break
              }
            }
          }else{
            dispatch(fetchMorePagesFailure(response.error))
          }

          break
        }
        case false:{
          dispatch(fetchPagesBegin());
          let response = await APICall(url,null,"GET")
          if(response.body!=null){
            switch(response.body.code){
              case 200:{
                dispatch(fetchPagesSuccess(response.body.payload));
                break;
              }
              default:{
                dispatch(fetchPagesFailure(response.body.message))
                break
              }
            }
          }else{
            dispatch(fetchPagesFailure(response.error))
          }
          break
        }
        default:
          break
      }

  };
}
