import {
  SWITCH_LANGUAGE,
  SWITCH_URLS,
  SET_ACTIVE_MENU

} from '../actions/commonActions.js';

const initialState = {
    language:null,
    switchUrls:{"en":"/en","sw":"/"},
    activeMenu:null
};

export default function commonReducer(state = initialState, action) {
  switch(action.type) {
      
    case SWITCH_LANGUAGE:
      return {
        ...state,
        language:action.payload.targetLanguage
      };

    case SWITCH_URLS:
      return {
        ...state,
        switchUrls:{...action.payload.urlsObject}
      };

    case SET_ACTIVE_MENU:
      return{
        ...state,
        activeMenu:action.payload.activeMenu
      }

    default:
      return state;
  }
}