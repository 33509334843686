import {
  FETCH_PAGES_BEGIN,
  FETCH_PAGES_SUCCESS,
  FETCH_PAGES_FAILURE,

  FETCH_MORE_PAGES_BEGIN,
  FETCH_MORE_PAGES_SUCCESS,
  FETCH_MORE_PAGES_FAILURE,

  FETCH_PAGE_BEGIN,
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_FAILURE,


} from '../actions/pagesActions.js';

const initialState = {
  pages: [],
  selectedPage: null,
  loading: false,
  error: null,
  success: false,
  lastPullCount: 0
};

export default function pagesReducer(state = initialState, action) {
  switch(action.type) {
      
    case FETCH_PAGES_BEGIN:
      return {
        ...state,
        pages:[],
        loading: true,
        error: null
      };

    case FETCH_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPage: null,
        lastPullCount: action.payload.pages === null ? 0 : action.payload.pages.length,
        pages: action.payload.pages === null ? [] : action.payload.pages
      };

    case FETCH_PAGES_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
        pages: []
      };



    case FETCH_MORE_PAGES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_MORE_PAGES_SUCCESS:
      if(action.payload.pages.payload!==null){
          let existingAccounts = state.pages.slice()
          existingAccounts = existingAccounts.concat(action.payload.pages.payload)
          return {
            ...state,
            loading: false,
            pages: existingAccounts,
            lastPullCount: action.payload.pages.payload===null ? 0 : action.payload.pages.payload.length,
          };        
      }else{
          return {
            ...state,
            loading: false,
            lastPullCount:0
          };        
      }

    case FETCH_MORE_PAGES_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
        pages: []
      };


    case FETCH_PAGE_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null,
        selectedPage:null
      };

    case FETCH_PAGE_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        selectedPage: action.payload.page,
        loading: false,
      };

    case FETCH_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
      };


    default:
      return state;
  }
}