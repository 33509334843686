import {
  FETCH_RESOURCES_BEGIN,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCES_FAILURE,

  FETCH_MORE_RESOURCES_BEGIN,
  FETCH_MORE_RESOURCES_SUCCESS,
  FETCH_MORE_RESOURCES_FAILURE,

  FETCH_RESOURCE_BEGIN,
  FETCH_RESOURCE_SUCCESS,
  FETCH_RESOURCE_FAILURE,

} from '../actions/resourcesActions.js';

const initialState = {
  resources: [],
  selectedResource: null,
  loading: false,
  error: null,
  success: false,
  lastPullCount: 0
};

export default function resourcesReducer(state = initialState, action) {
  switch(action.type) {
      
    case FETCH_RESOURCES_BEGIN:
      return {
        ...state,
        resources:[],
        loading: true,
        error: null
      };

    case FETCH_RESOURCES_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedResource: null,
        lastPullCount: action.payload.resources === null ? 0 : action.payload.resources.length,
        resources: action.payload.resources === null ? [] : action.payload.resources
      };

    case FETCH_RESOURCES_FAILURE:
      return {
        ...state,
        loading: false,
        selectedAccount: null,
        success:false,
        error: action.payload.error,
        resources: []
      };



    case FETCH_MORE_RESOURCES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_MORE_RESOURCES_SUCCESS:
      if(action.payload.resources.payload!==null){
          let existingAccounts = state.resources.slice()
          existingAccounts = existingAccounts.concat(action.payload.resources.payload)
          return {
            ...state,
            loading: false,
            resources: existingAccounts,
            lastPullCount: action.payload.resources.payload===null ? 0 : action.payload.resources.payload.length,
          };        
      }else{
          return {
            ...state,
            loading: false,
            lastPullCount:0
          };        
      }

    case FETCH_MORE_RESOURCES_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
        resources: []
      };


    case FETCH_RESOURCE_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_RESOURCE_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        selectedResource: action.payload.course,
        loading: false,
      };

    case FETCH_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
      };


    default:
      return state;
  }
}