import {

  FETCH_PROGRESS_BEGIN,
  FETCH_PROGRESS_SUCCESS,
  FETCH_PROGRESS_FAILURE,

  FETCH_MORE_PROGRESS_BEGIN,
  FETCH_MORE_PROGRESS_SUCCESS,
  FETCH_MORE_PROGRESS_FAILURE,


} from '../actions/progressActions.js';

const initialState = {
  progress: [],
  loading: false,
  error: null,
  success: false,
  lastPullCount: 0
};

export default function progressReducer(state = initialState, action) {
  switch(action.type) {
      
    case FETCH_PROGRESS_BEGIN:
      return {
        ...state,
        progress:[],
        loading: true,
        error: null
      };

    case FETCH_PROGRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAccount: null,
        lastPullCount: action.payload.progress.payload===null ? 0 : action.payload.progress.payload.length,
        progress: action.payload.progress.payload === null ? [] : action.payload.progress.payload
      };

    case FETCH_PROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        selectedAccount: null,
        success:false,
        error: action.payload.error,
        progress: []
      };



    case FETCH_MORE_PROGRESS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_MORE_PROGRESS_SUCCESS:
      if(action.payload.progress.payload!==null){
          let existingAccounts = state.progress.slice()
          existingAccounts = existingAccounts.concat(action.payload.progress.payload)
          return {
            ...state,
            loading: false,
            progress: existingAccounts,
            lastPullCount: action.payload.progress.payload===null ? 0 : action.payload.progress.payload.length,
          };        
      }else{
          return {
            ...state,
            loading: false,
            lastPullCount:0
          };        
      }

    case FETCH_MORE_PROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        success:false,
        error: action.payload.error,
        progress: []
      };


    default:
      return state;
  }
}