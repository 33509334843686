import React from 'react';
import {connect} from "react-redux";

import {Modules,CourseHeader,PullMore,Loading,Error} from "./Embeds/Common.js"
import {fetchCourse,fetchUserIndividualCourseProgress} from "../actions/coursesActions"
import {switchURLS,setActiveMenu} from "../actions/commonActions"
import {fetchModules} from "../actions/modulesActions"

import {CoursesLabel,AnErrorOccuredLabel,PageDoesNotExistError} from "../WebCopy.js";
import {APICallCount,getLanguage,CommonTitle,Analytics} from "../API"

const mapStateToProps = (state) => {
  return {
  	loading: state.coursesReducer.loading,
  	error: state.coursesReducer.error,
  	success: state.coursesReducer.success,
    selectedCourse: state.coursesReducer.selectedCourse, 
    lastPullCount: state.modulesReducer.lastPullCount,
    individualCompletedModules: state.coursesReducer.individualCompletedModules,

    modulesLoading: state.modulesReducer.loading,
    modules: state.modulesReducer.modules,
    modulesError: state.modulesReducer.error,

    loggedIn: state.accountsReducer.loggedIn
  };
}

const mapDispatchToProps = {
  fetchUserIndividualCourseProgress,
  setActiveMenu,
  fetchModules,
  fetchCourse,
  switchURLS
}


class CoursePage extends React.Component{
  constructor(props){
    super(props)

    this.courseInfo = <Loading />

    this.title = <span className="fa fa-spin fa-spinner"/>
    this.progressDone = 0

    this.breadCrumbs = [];
    this.processedCourse = false
    this.progressFetched= false
    
    this.start = 0
    this.append = false

    this.modulesCount = 0

    this.modules = <Loading />
  }


  computeSlug = () =>{
      this.slug = window.location.pathname.split("/")
      this.slug = this.slug[this.slug.length-1]

  }

  componentDidMount(){
    window.scrollTo(0,0)
    this.computeSlug()
    this.props.setActiveMenu("courses")
    document.title = "... | "+CommonTitle 
    this.props.fetchCourse(this.slug)
  }

  componentDidUpdate(props){
    if(this.props.location.key !== props.location.key || this.props.loggedIn !== props.loggedIn){
        window.scrollTo(0,0)

        this.courseInfo = <Loading />
        this.title = <span className="fa fa-spin fa-spinner"/>
        this.breadCrumbs = [];

        this.processedCourse = false


        this.computeSlug()
        this.props.fetchCourse(this.slug)
    }
  }


  calculateBreadCrumbs = () =>{
        this.breadCrumbs = [];
        let link = {}

        link["name"] = CoursesLabel()
        if(getLanguage()==="en"){
          link["path"] = "/courses"
        }else{
          link["path"] = "/kozi"
        }
        this.breadCrumbs.push(link);

        link = {}
        if(getLanguage()==="en"){
          link["name"] = this.props.selectedCourse.english_name
          link["path"] = "/courses/"+this.props.selectedCourse.english_slug
        }else{
          link["name"] = this.props.selectedCourse.swahili_name
          link["path"] = "/kozi/"+this.props.selectedCourse.swahili_slug
        }
        this.breadCrumbs.push(link);
  }

  processCourse = () =>{
    if(this.props.loading===true && this.props.selectedCourse===null){
        return
    }

    if(this.props.error!==null){
      this.title = AnErrorOccuredLabel()
      this.courseInfo = <Error Message={PageDoesNotExistError()} />
      return
    }

    if(this.props.selectedCourse===null){
      return
    }


    let modulesCovered = 0

    for(let x = 0 ; x < this.props.individualCompletedModules.length ; x++){
      if(this.props.individualCompletedModules[x] !== null && this.props.individualCompletedModules[x].course===this.props.selectedCourse.ID){
          modulesCovered++
      }
    }


    this.progressDone = (modulesCovered / this.props.selectedCourse.modules_count) * 100

    document.title = ( getLanguage() === "en" ? this.courseInfo.english_name : this.courseInfo.swahili_name ) +" | "+CommonTitle
    if(this.processedCourse===false){
        Analytics()

        this.calculateBreadCrumbs()

        this.courseInfo = this.props.selectedCourse

        this.title = getLanguage()==="en" ? this.props.selectedCourse.english_name : this.props.selectedCourse.swahili_name
        this.props.switchURLS({"en":"/courses/"+this.props.selectedCourse.english_slug,"sw":"/kozi/"+this.props.selectedCourse.swahili_slug})
        this.processedCourse = true

        this.modulesCount = this.props.selectedCourse.modules_count
        this.fetchModules()
    }

  }

  fetchModules = () =>{
      this.props.fetchModules(this.start,APICallCount,null,this.props.selectedCourse.ID,this.append)
      if(this.props.loggedIn!==null && this.progressFetched===false){
        this.props.fetchUserIndividualCourseProgress(this.props.loggedIn,this.props.selectedCourse.ID,0,10000)
        this.progressFetched=true
      }
  }

  PullMore = () =>{
    this.start = this.props.modules.length;
    this.append = true
    this.fetchModules()
  }

  processModules = () =>{
    if(this.props.modulesLoading===true && this.props.modules.length===0){
      return
    }

    if(this.props.modulesError!==null){
      this.modules = <Error message={this.props.modulesError} />
      return
    }

    this.modules = <Modules 
                      Modules={this.props.modules} 
                      coveredModules={this.props.individualCompletedModules}
                      modulesCount={this.modulesCount}
                      />
  }

	render(){
    this.processCourse()
    this.processModules()
		return (
      <>
        <CourseHeader 
          courseDetails={this.props.selectedCourse} 
        />

        <div className="row noMargin">
          <div className="container coursesContainerListing">


          {
            this.props.selectedCourse!==null && this.props.modules.length > 0 ? 
                  this.modules
                  :
                  <Loading />
          }

            {
              this.props.lastPullCount!==APICallCount ? 
              null :
              <PullMore 
                  loading={this.props.modulesLoading} 
                  action={this.PullMore}
              />
            }
          </div>
        </div>
      </>
		)		
	}

}

const comp = connect(mapStateToProps,mapDispatchToProps)(CoursePage);
export default comp;
