import {combineReducers} from "redux";
import accountsReducer from "../reducers/accountsReducer"
import coursesReducer from "../reducers/coursesReducer"
import modulesReducer from "../reducers/modulesReducer"
import resourcesReducer from "../reducers/resourcesReducer"
import progressReducer from "../reducers/progressReducer"
import pagesReducer from "../reducers/pagesReducer"
import commonReducer from "../reducers/commonReducer"

export default combineReducers({
  accountsReducer,
  coursesReducer,
  modulesReducer,
  resourcesReducer,
  progressReducer,
  pagesReducer,
  commonReducer
})